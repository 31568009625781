import {forwardRef,useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Slide,
    Stack,
    Typography
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

import { styled,useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { DropdownMenu } from './menus';
import { DefaultTooltip } from './buttons';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    // Control entire popop window styling
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 5,
        height: '90vh'
    }
}));

export function FilterButton({filterComponents,handleReset}){

    const [open,setOpen] = useState(false);

    return(
        <>
            <Button onClick={() => {setOpen(true)}}
            startIcon={<TuneIcon />}
            >
                Filter
            </Button>
            <FullScreenFilterContainer open={open} setOpen={setOpen} 
                filterComponents={filterComponents} handleReset={handleReset} />
        </>
    )
}

export function FullScreenFilterContainer({open,setOpen,filterComponents,handleReset}){

    function handleClose() {setOpen(false)}

    return (
        <>
        <BootstrapDialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{p: 1}}>
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">

                <IconButton
                onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h6">
                    Filter & Sort
                </Typography>

                <Button onClick={handleReset}>
                    Reset
                </Button>

                </Stack>
            </DialogTitle>
            
            <DialogContent>
                {filterComponents}
            </DialogContent>
            <DialogActions sx={{justifyContent:'center'}}>
                <Button onClick={handleClose}>Confirm</Button>
            </DialogActions>
        </BootstrapDialog>
        </>
    )
}

export function FilterMenu(props){

    const {name,variant,children,...otherProps} = props;

    const filterName = name != null ? name : "Filter";
    const variantName = variant != null ? variant : "contained";

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Button 
        variant={variantName}
        onClick={(event) => {setAnchorEl(event.currentTarget)}}
        startIcon={largeScreen && <TuneIcon />}
        >
            {largeScreen ?  filterName : <TuneIcon />}
        </Button>
        <DropdownMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            '& .MuiPaper-root': {
                mt: 1,
                minWidth: 200
            }
        }}
        >
            {children}
        </DropdownMenu>
        </>
    )
}

export function FilterMenuItem(props){

    const {sx,...otherProps} = props;

    return (
        <MenuItem
        disableRipple 
        sx={{ 
            '&:hover' : {backgroundColor: 'transparent'},
            ...sx
        }}
        {...otherProps}
        />
    )
}

export function SortByMenu(props){

    // options - List of dictionaries defining the title and the sortFn
    // Example: options = [
    // { title: Highest Grade, sortFn: descendingGradeSortFn }
    // ]

    const {selectedIndex,options,onChangeFn,...otherProps} = props;

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Button onClick={(event) => {setAnchorEl(event.currentTarget)}}
            startIcon={largeScreen && <SwapVertIcon />}
            >
            {largeScreen ? "Sort" : <SwapVertIcon /> }
        </Button>
        <DropdownMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            '& .MuiPaper-root': {
                mt: 1,
                minWidth: 200
            }
        }}
        >
            {options.map((option, index) => (
                <MenuItem
                    key={option.title}
                    selected={index === selectedIndex}
                    value={index}
                    onClick={onChangeFn}
                >
                    <ListItemText primary={option.title} />
                    {index === selectedIndex &&
                    <ListItemIcon sx={{justifyContent: 'flex-end'}}>
                        <CheckIcon fontSize="small" color="success"/>
                    </ListItemIcon>
                    }
                </MenuItem>
            ))}
        </DropdownMenu>
        </>
    )
}

export function SliderMenuItem(props){

    const {title,tooltipText,children,...otherProps} = props;

    return (
        <FilterMenuItem sx={{width: 300}}>
            <Stack 
            direction="column"
            spacing={1} 
            width={"100%"}
            sx={{px: 1}}
            >
                <TitleWithTooltip
                title={title}
                tooltipText={tooltipText}
                />
                <Stack direction="row">
                    {children}
                </Stack>
            </Stack>
        </FilterMenuItem>
    )
}

function TitleWithTooltip({title,tooltipText}){

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
            <Typography>{title}</Typography>
            <DefaultTooltip text={tooltipText} />
        </Stack>
    )
}