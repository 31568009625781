
import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { ResponsiveContainer } from '../../../components/responsive';
import { TeamPredictionResultsContainer } from "../components/team/team-prediction-results-container";
import { PredictionResultsFAQs } from "../components/common/faqs";
import { SeoHelmet } from "../../../components/seo";

export default function TeamPredictionResults() {

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        if ( state === null 
            || !state.league
            || !state.start_time
            || !state.end_time
        ) {
            navigate('/')
        }
        setLoading(false);
    }, [loading]);

    return (
        <>
        <SeoHelmet 
        title="Team Prediction Results"
        description="Compare your machine learning models' predictions to popular sportsbooks to find value."
        />
        <ResponsiveContainer>
            {!loading &&
            <TeamPredictionResultsContainer league={state.league} startTime={state.start_time} endTime={state.end_time} />
            }
            <PredictionResultsFAQs />
        </ResponsiveContainer>
        </>
    );
}