import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import {
    Badge,
    Box,
    Button,
    Paper,
    Stack,
    Typography
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useAuth} from '../../../hooks/auth';
import TeamPredictionImageSrc from '../../../assets/graphics/landing-page/TeamPredictionGraphic.webp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function HeaderTextAndAction() {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const auth = useAuth();

    const buttonSize = isMobile ? "small" : "large";

    const handleViewWinningModelsClicked = () => {

        navigate('/leaderboard');

        ReactGA.event({
            category: 'Conversion',
            action: 'Click',
            label: "View Winning Models",
        });
    }

    const handleTryForFreeClicked = () => {

        navigate('/register');

        ReactGA.event({
            category: 'Conversion',
            action: 'Click',
            label: "Try for Free",
        });
    }

    const sidePadding = {xs: 2, md: 4};
    const verticalPadding = {xs: 1, md: 1.5};
    const buttonProps = {
        px: sidePadding, 
        py: verticalPadding,  
        borderRadius: 3, 
        fontSize: "large", 
        fontWeight: "bold"
    };

    return (
        <Stack 
        direction="column" 
        alignItems={{xs: 'center', md: 'flex-start'}} 
        textAlign={{xs: 'center', md: 'left'}} 
        spacing={3} 
        width="100%"
        >
            <Typography gutterBottom variant="h2" component="div" sx={{fontWeight: "bold", color:"secondary.main"}}>
                    Custom Sports Betting Models using Machine Learning
            </Typography>
            <Typography variant="h6" component="div">
                    Predict team scores and individual player performances by choosing the statistics 
                    that <strong>YOU</strong> think impact the outcome. Anyone can be the one to solve sports.
            </Typography>

            <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{ pt: {xs: 0 , sm: 2} }}>
                {!auth.loading && !auth.authenticated ?
                <Button
                variant="outlined"
                size={buttonSize} 
                onClick={()=> handleTryForFreeClicked()} 
                sx={buttonProps}
                endIcon={<ArrowRightAltIcon />}
                >
                    Try for Free
                </Button>
                :
                <Button
                variant="outlined"
                size={buttonSize} 
                onClick={()=> {navigate('/changelog')}} 
                sx={buttonProps}
                endIcon={<ArrowRightAltIcon />}
                >
                    What's new?
                </Button>
                }
                <Badge badgeContent={"NEW"} color="secondary" >
                    <Button 
                    variant="contained" 
                    size={buttonSize} 
                    onClick={()=> handleViewWinningModelsClicked()} 
                    sx={buttonProps}
                    >
                        Winning Models
                    </Button>
                </Badge>
            </Stack>

        </Stack>
    );
}

function HeaderGraphic(){

    return (
        <Paper variant="outlined" sx={{ textAlign: 'center', width: "100%", height: "100%"}}>
            <Box sx={{alignItems: 'center', width: '100%', p: {xs:0.5,md:2}}}>
                <Box 
                component="img"
                src={TeamPredictionImageSrc}
                alt="Prediction Example"
                height={"auto"}
                width={"100%"}
                />
            </Box>
        </Paper>
    )
}

export function Header(){

    const theme = useTheme();

    return (
        <Stack direction={{xs:"column",md:"row"}} alignItems={{xs:'center',md:'flex-start'}} spacing={{xs:4,md: 2, lg:4}}>

            <HeaderTextAndAction />
            <HeaderGraphic />

        </Stack>
    )
}