import { calculateProfit } from "../features/profile/components/tracking/common";

export const USD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger'
});

export const countBetResults = (bets) => {
    const countResults = bets.reduce((acc, obj) => {
        const result = obj.result;
        acc[result] = (acc[result] || 0) + 1;
        return acc;
    }, {});

    return countResults;
}

export const calculateNetProfits = (bets) => {
    const netProfits = bets.reduce((acc, obj) => {
        if (obj.result === 'win'){
            return acc + calculateProfit(obj.stake,obj.price);
        }
        else if (obj.result === 'loss'){
            return acc - obj.stake;
        }
        else {
            return acc + 0;
        }
    }, 0);

    return netProfits;
}

export const sumStakes = (bets) => {

    const completedGameReults = ['win','loss','push'];
    // Sum all stakes from completed bets
    const totalStakes = bets.reduce((acc, obj) => {
        if (completedGameReults.includes(obj.result)){
            return acc + obj.stake;
        }
        else {
            return acc + 0;
        }
    }, 0);

    return totalStakes;
}

export const calculateNetUnits = (bets) => {

    const netUnits = bets.reduce((acc, obj) => {
        if (obj.result === 'win'){
            return acc + calculateProfit(1,obj.price);
        }
        else if (obj.result === 'loss'){
            return acc - 1;
        }
        else {
            return acc + 0;
        }
    }, 0);

    return netUnits;
}