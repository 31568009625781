
// Basic Component Imports
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Skeleton,Stack } from '@mui/material';

import { ResponsiveContainer } from '../../../components/responsive';
import { TeamStatSelection } from '../components/team/team-stat-selection-table';
import { CreateTeamModelFAQs } from '../components/team/faqs';
import { CreateTeamModelWalkthrough } from '../../../components/onboarding/walkthroughs';
import { CreateTeamModelHeader } from '../components/common/headers';
import { SeoHelmet } from '../../../components/seo';
import { TeamExpertModelButton } from '../components/team/expert-model-button';
import { UserPopup } from '../../auth/components/popups';
import PaymentOptionsPopup from '../../pricing/components/payment-popup';

export default function CreateTeamModel() {
    const { target } = useParams();
    const location = useLocation();
    
    const [editEnabled,setEditEnabled] = useState(false);

    // Loading the location state takes some time to render
    // and components are rendering before it is done, so this
    // state is used to prevent that
    const [loading,setLoading] = useState(true);

    // Popups
    const [userPopupOpen,setUserPopupOpen] = useState(false);
    const [pricingPopupOpen,setPricingPopupOpen] = useState(false);

    useEffect(() => {
        setEditEnabled(location.state !== null ? location.state.edit_enabled : false);
        setLoading(false);
    },[location]);

    return (
        <>
            <SeoHelmet 
            title="Create Team Model"
            description="Create a custom betting model to predict spreads and totals for 
            NFL, NBA, NCAAF, and NCAAB"
            />
            <ResponsiveContainer>
                <CreateTeamModelHeader league={target} />
                <Stack direction="row" spacing={2} justifyContent={{xs: "space-between" , md: "flex-end"}}
                sx={{mb:{xs:2,md:1},mt: {xs:2,md:0}}}
                >
                    <TeamExpertModelButton 
                    league={target} 
                    setUserPopupOpen={setUserPopupOpen}
                    setPricingPopupOpen={setPricingPopupOpen}
                    />
                    <CreateTeamModelWalkthrough />
                </Stack>
                {loading ?
                <Skeleton variant="rectangular" animation="wave" width="100%" height={600}/>
                :
                <TeamStatSelection 
                league={target} 
                editEnabled={editEnabled} 
                setEditEnabled={setEditEnabled} 
                setUserPopupOpen={setUserPopupOpen}
                setPricingPopupOpen={setPricingPopupOpen}
                />
                }
                <CreateTeamModelFAQs />
            </ResponsiveContainer>

            {/* This popup will only try to train a model and are not logged in*/}
            <UserPopup open={userPopupOpen} setOpen={setUserPopupOpen} />
  
            {/* This popup will only get rendered if we are logged in and are unauthorized */}
            <PaymentOptionsPopup open={pricingPopupOpen} setOpen={setPricingPopupOpen} isLoggedIn={true} unauthorized={true}/>
        </>
    );
}
