
import { FlaskClient } from "./setup";

export const playerTrainRequest = (requestBody,params,handleResponse,handleError) => {
    FlaskClient.post('player/models/train',
      requestBody,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerPredictionsRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/models/predictions',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSummaryRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/models/summary')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSlimSummaryRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/models/summary',
    {
    params: {slim: true}
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

export const playerDeleteRequest = (params,handleResponse,handleError) => {
    FlaskClient.delete('player/models/delete',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerStoreRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('player/models/store',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

export const playerScheduleRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/models/schedule',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerUpdateRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('player/models/update',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerSaveRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('player/models/save',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerModelsRequest = (handleResponse,handleError) => {
    FlaskClient.get('player/models/')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerStatisticsRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/models/statistics',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerValidDatesRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/models/valid-dates',
    {
      params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerHyperparametersRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/models/hyperparameters',
    {
      params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerModelTrackingRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('player/models/tracking',
    null,
    {
      params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

export const playerBetsPostRequest = (jsonBody,handleResponse,handleError) => {
    FlaskClient.post('player/bets/',
      jsonBody,null
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerBetsGetRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/bets/',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerBetsLatestRequest = (jsonBody,handleResponse,handleError) => {
    FlaskClient.put('player/bets/latest',
    jsonBody,null
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const playerBetDeleteRequest = (params,handleResponse,handleError) => {
    FlaskClient.delete('player/bets/',
    {
    params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
  }

  export const playerBetsLeaderboardRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('player/bets/leaderboard',
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}