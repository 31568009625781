import { useState } from 'react';
import {
    Checkbox,
    InputLabel,
    FormControl,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function DropdownMenu(props){

    return (
        <Menu
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        }}
        {...props}
        >
            {props.children}
        </Menu>
    )
}

const FILTER_MENU_ITEM_HEIGHT = 48;
const FILTER_MENU_ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: FILTER_MENU_ITEM_HEIGHT * 4.5 + FILTER_MENU_ITEM_PADDING_TOP,
        width: 200,
      },
    },
};
const defaultDropdownWidth = 250;

export function MultiSelect({label,values,setValues,availableValues,availableValuesDisplay=null,width=null,size='medium'}){
    
    const inputId = "input-"+label;

    // Ensure available values display exists and is the same length as the available values
    // If not, set the display to be the values
    if (availableValuesDisplay === null || availableValuesDisplay.length !== availableValues.length) {
        availableValuesDisplay = availableValues;
    }

    const elementWidth = width ? width : defaultDropdownWidth;

    const renderValueDisplay = (selected) => {
        // Filter out null values for display in cases of re-rendering display values because they
        // are not a useState. Hacky but it works.
        const displayValues = selected.map( (value) => availableValuesDisplay[availableValues.indexOf(value)]).filter(value => value != null);
        return displayValues.length == availableValues.length ? 'All' : displayValues.join(', ');
    }

    return (
        <FormControl sx={{ m: 0, width: elementWidth }} size={size}>
            <InputLabel id={inputId}>{label}</InputLabel>
            <Select
            labelId={inputId}
            id={`${label}-multiple-checkbox`}
            multiple
            value={values}
            onChange={(event)=>setValues(event.target.value)}
            input={<OutlinedInput label={label} />}
            renderValue={renderValueDisplay}
            // native={isMobile}
            MenuProps={MenuProps}
            sx={{height: '100%'}}
            disabled={availableValues.length === 0}
            >
            {availableValues.map((item,index) => (
                <MenuItem key={index} value={item}>
                <Checkbox checked={values.indexOf(item) > -1} />
                <ListItemText primary={availableValuesDisplay[index]} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export function SingleSelect({label,value,availableValues,onChangeFn,availableValuesDisplay=null,width=null,enabled=true,size='medium'}){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    // Ensure available values display exists and is the same length as the available values
    // If not, set the display to be the values
    if (availableValuesDisplay === null || availableValuesDisplay.length !== availableValues.length) {
        availableValuesDisplay =availableValues;
    }
    const elementWidth = width ? width : defaultDropdownWidth;

    const inputId = "input-"+label;

    return (
        <FormControl sx={{ m: 0, width: elementWidth}} size={size} disabled={!enabled}>
            <InputLabel id={inputId}>{label}</InputLabel>
            <Select
            labelId={inputId}
            id={`${label}-select`}
            value={value}
            onChange={onChangeFn}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => availableValuesDisplay[availableValues.indexOf(selected)]}
            // native={isMobile}
            MenuProps={MenuProps}
            sx={{height: '100%'}}
            >
            {availableValues.map((item,index) => (
                <MenuItem key={item} value={item}>
                <ListItemText primary={availableValuesDisplay[index]} />
                {value === item &&
                <ListItemIcon sx={{justifyContent: 'flex-end'}}>
                    <CheckIcon fontSize="small" color="success"/>
                </ListItemIcon>
                }
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export function HorizontalTabMenu({value,setValue,values}){

    // IMPORTANT: The WAY better way to do this is using MUI ToggleButtonGroup
    // and ToggleButton. See pricing page or MUI docs for examples.
          
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const typographyVariant = isMobile ? "caption" : "body";

    return (
    <Tabs 
    value={value} 
    onChange={handleChange} 
    centered
    size="small"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    sx={{
        borderRadius: 0,
        // backgroundColor: 'background.paper',
        px: 0,
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiTabs-indicator': {
            display: 'none',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'red',
        },
    }}
    >
        {values.map((item,index) => (
            <Tab 
            key={index}
            disableRipple
            sx={{
                // minWidth: 90,
                px: 1.25,
                py: 0,
                minHeight: 40,
                backgroundColor: 'transparent',
                border: 1,
                borderColor: '#57636f',
                textTransform: 'none',
                mx: 0,

                '&.Mui-selected': {
                    color: 'white',
                    backgroundColor: 'primary.main',
                    border: 0
                },
                
                borderTopRightRadius: index+1 === values.length ? 4 : 0,
                borderBottomRightRadius: index+1 === values.length ? 4 : 0,
                borderTopLeftRadius: index === 0 ? 4 : 0,
                borderBottomLeftRadius: index === 0 ? 4 : 0,
            }}
            label={
            <Typography variant={"body"}>
                {item}
            </Typography>
            } 
            value={item} 
            />
        ))}
    </Tabs>
);
}