import {
    Box,
    Container,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
  } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import { ResponsiveDialog } from '../../../components/responsive';


export function DemoVideoPopup({open,setOpen}){

    const theme = useTheme();
  
    const handleClose = () => {
      setOpen(false);
    }
  
    return (
      <ResponsiveDialog open={open}
      sx={{
        '& .MuiPaper-root': {
              // The actual popup styling
              width: {xs: '90%',md: "80%"},
              height: "100%",
              maxHeight: 800,
              maxWidth: theme.breakpoints.values.lg
            //   backgroundColor: theme.palette.background.paper
          },
      }}
      
      >
        <IconButton
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.main,
                zIndex: 2
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle>
            Demo
        </DialogTitle>
        <DialogContent>
          <Box sx={{width: '100%',height: '100%', boxShadow: 3}}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QHFZZgb3yO0?si=w9aVyZHkn9YkxyNU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
            </iframe>
          </Box>
        </DialogContent>
        
      </ResponsiveDialog>
    )
}

export function DemoVideoContainer(){

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" textAlign="left" gutterBottom>
        Demo
      </Typography>
      <Box sx={{width: '100%',height: {xs:400,md: 700}, boxShadow: 3}}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QHFZZgb3yO0?si=w9aVyZHkn9YkxyNU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
        </iframe>
      </Box>
    </Container>
  )
}