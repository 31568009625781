import {useEffect, useState} from 'react';
import {
    Container,
} from '@mui/material';

import { TrackingDashboard } from './dashboard';
import { teamBetsGetRequest } from '../../../../hooks/api/team';
import { ErrorSnackbar } from '../../../../components/snackbars';

export default function TeamTrackingContainer() {

    const [trackingType,setTrackingType] = useState('Manual');

    const [allBets,setAllBets] = useState([]);
    const [filteredBets,setFilteredBets] = useState([]);
    const [trackedModels,setTrackedModels] = useState([]);
    const [downloadLatestBetsAllowed,setDownloadLatestBetsAllowed] = useState(false);
    const [loading,setLoading] = useState(true);

    // Error handling states
    const [lockContent,setLockContent] = useState(false);
    const [trackingErrorSnackbarOpen, setTrackingErrorSnackbarOpen] = useState(false);
    const [trackingErrorSnackbarMessage, setTrackingErrorSnackbarMessage] = useState('Something went wrong');

    const handleResponse = (response) => {
      
      const bets = response.data.bets
      setAllBets(bets);
      setFilteredBets(bets);

      setTrackedModels(response.data.tracked_models);

      setDownloadLatestBetsAllowed(response.data.download_latest_bets_allowed);

      setLoading(false);
    }

    const handleError = (error) => {
      setAllBets([]);
      setFilteredBets([]);
      setTrackedModels([]);
      setDownloadLatestBetsAllowed(false);

      if (error.response){
          if (error.response.status === 401) {
              // Lacks authorization - Only lock content when we
              // know what the error is exactly
              setLockContent(true);
              setTrackingErrorSnackbarMessage(error.response.data.message);
          }
          else {
              setTrackingErrorSnackbarMessage('Something went wrong');
          }
      }
      setTrackingErrorSnackbarOpen(true);

      setLoading(false);
    }

    useEffect(()=> {
      setLoading(true);
      setLockContent(false);

      const params = {
        tracking_type : trackingType.toLowerCase(),
        group_models: false
      };
      teamBetsGetRequest(params,handleResponse,handleError);
    },[trackingType]);

    return (
        <Container maxWidth="lg" disableGutters sx={{mt: -2}}>

            <TrackingDashboard
            allBets={allBets}
            setAllBets={setAllBets}
            filteredBets={filteredBets}
            setFilteredBets={setFilteredBets}
            trackedModels={trackedModels}
            downloadLatestBetsAllowed={downloadLatestBetsAllowed}
            loading={loading}
            lockContent={lockContent}
            trackingType={trackingType}
            setTrackingType={setTrackingType}
            />

            <ErrorSnackbar
            open={trackingErrorSnackbarOpen}
            setOpen={setTrackingErrorSnackbarOpen}
            message={trackingErrorSnackbarMessage}
            />
            
        </Container>
    );
};