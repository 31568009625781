import { useState,useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BetTypeToggle } from "../../profile/components/tracking/common";
import { HorizontalScrollStackWithArrows } from "../../profile/components/tracking/filters";
import { SingleSelect } from "../../../components/menus";
import { capitalize } from "../../../utils/general";


export function LeaderboardFilter(
    {leaderboardData,setFilteredLeaderboardData,trackingType,setTrackingType}
){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    // Time Filter
    const availableTimeFilters = ['30d','7d',];
    const availableTimeFiltersDisplay = ['Last 30','Last 7',];
    const [timeFilter,setTimeFilter] = useState('7d');
    const timeFilterFn = ({time_range}) => time_range == timeFilter;

    // Market. Set based on what is available in all.
    const validMarkets = leaderboardData.reduce((acc, model) => {
        if (!acc.includes(model.market)) acc.push(model.market);
        return acc;
    }, []);
    const validMarketsDisplay = validMarkets.map(validMarket => capitalize(validMarket));
    const [marketSelection,setMarketSelection] = useState("cumulative");
    const marketFilterFn = ({market}) => marketSelection === market;

    useEffect(()=> {
        if (leaderboardData.length !== 0) {
            let filteredData = leaderboardData.slice().filter(timeFilterFn);
            filteredData = filteredData.filter(marketFilterFn);
            setFilteredLeaderboardData(filteredData);
        }
    },[leaderboardData,timeFilter,marketSelection]);

    const menuWidth = largeScreen ? 150 : 125;

    return (
        <HorizontalScrollStackWithArrows>
            <BetTypeToggle
            trackingType={trackingType} 
            setTrackingType={setTrackingType} 
            />

            <SingleSelect
            label={null}
            value={timeFilter}
            availableValues={availableTimeFilters}
            availableValuesDisplay={availableTimeFiltersDisplay}
            onChangeFn={(event) => setTimeFilter(event.target.value)}
            width={menuWidth}
            size='small'
            />

            <SingleSelect
            label={"Market"}
            value={marketSelection}
            availableValues={validMarkets}
            availableValuesDisplay={validMarketsDisplay}
            onChangeFn={(event) => setMarketSelection(event.target.value)}
            width={menuWidth}
            size='small'
            />
        </HorizontalScrollStackWithArrows>
    )
}