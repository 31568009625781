import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../components/page-layout';
import LandingPage from '../features/landing/pages/landing-page';
import LoginPage from '../features/auth/pages/login';
import Profile from '../features/profile/pages/profile';
import ForgotPasswordPage from '../features/auth/pages/forgot-password';
import NoPage from '../components/no-page';
import ResourceNotFound from '../components/resource-not-found';
import SignupPage from '../features/auth/pages/signup';
import PrivacyPolicy from '../features/legal-documents/pages/privacy-policy';
import TermsOfUse from '../features/legal-documents/pages/terms-of-use';


import TeamPredictionResults from '../features/predictions/pages/team-prediction-results';
import PlayerPredictionResults from '../features/predictions/pages/player-prediction-results';
import PaymentSuccess from '../features/pricing/pages/payment-success';
import Forum from '../features/forum/pages/forum';
import PricingPage from '../features/pricing/pages/pricing-page';
import VerifyPage from '../features/auth/pages/verify';
import ResetPasswordPage from '../features/auth/pages/reset-password';
import UnsubscribePage from '../features/auth/pages/unsubscribe';

import PrivateRoutes from '../hooks/private-routes';

import TeamModelWrapper from "../features/validation/TeamModelWrapper";
import PlayerModelWrapper from "../features/validation/PlayerModelWrapper";
import ModelSummaryWrapper from "../features/validation/ModelSummaryWrapper";
import ChangelogPage from '../features/changelog/pages/changelog-page';
import MaintenancePage from '../components/maintenance-page';
import BlogMainPage from '../features/blog/pages/blog-main-page';
import BlogArticlePage from '../features/blog/pages/blog-article-page';
import GlossaryPage from '../features/glossary/pages/glossary';
import LeaderboardPage from '../features/leaderboard/pages/leaderboard';

export default function RouteProvider(){

    return (
        <Router>
            <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<LandingPage />} />
                <Route path="/login" exact element={<LoginPage />}/>
                <Route path="/register" exact element={<SignupPage />} />
                <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
                <Route path="/pricing" exact element={<PricingPage />} />
                <Route path="/create-team-model/:target" element={<TeamModelWrapper />}/>
                <Route path="/create-player-model/:target" element={<PlayerModelWrapper />}/>
                <Route path="/profile" exact element={<Profile />} />
                <Route element={<PrivateRoutes />}>
                    <Route path="/model-summary/:target" element={<ModelSummaryWrapper />} />
                    <Route path="/team-prediction-results" element={<TeamPredictionResults />} />
                    <Route path="/player-prediction-results" element={<PlayerPredictionResults />} />
                </Route>
                <Route path="/resource-not-found" element={<ResourceNotFound />} />
                <Route path="/verify" element={<VerifyPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/forum" element={<Forum />} />
                <Route path="/blog" element={<BlogMainPage />} />
                <Route path="/blog/:id" element={<BlogArticlePage />} />
                <Route path="/changelog" element={<ChangelogPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/unsubscribe" element={<UnsubscribePage />} />
                <Route path="/glossary/:target" element={<GlossaryPage />} />
                <Route path="/leaderboard" element={<LeaderboardPage />} />
                <Route path="*" element={<NoPage />} />
            </Route>
            </Routes>
        </Router>
    )
    
}

export function MaintenanceRouteProvider(){

    return (
        <Router>
            <Routes>
                <Route path="*" element={<MaintenancePage />} />
            </Routes>
        </Router>
    )
    
}