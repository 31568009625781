
import {SportsFootball, SportsBasketball, SportsBaseball} from '@mui/icons-material';

export const team_leagues = [
    "NFL",
    "NBA",
    "NCAAF",
    "NCAAB",
    "WNBA",
    "MLB",
]

export const player_leagues = [
    "NFL",
    "NBA",
    "WNBA",
    "MLB",
]

export const leagueConfig = {
    'nfl' : { id: 1,    display: 'NFL',     player_enabled: true},
    'nba' : { id: 2,    display: 'NBA',     player_enabled: true},
    'cfb' : { id: 3,    display: 'NCAAF',   player_enabled: false},
    'cbk' : { id: 4,    display: 'NCAAB',   player_enabled: false},
    'wnba': { id: 5,    display: 'WNBA',   player_enabled: true},
    'mlb' : { id: 6,    display: 'MLB',     player_enabled: true},
};

export const leagueKeyToDisplay = (key) => {
    return leagueConfig[key] ? leagueConfig[key].display : key;
}

export const leagueDisplayToKey = (display) => {
    let returnKey = null;
    Object.entries(leagueConfig).map(([key,leagueInfo]) => {
        if (display === leagueInfo.display) {
            returnKey = key;
        }
    });

    return returnKey;
}

export function LeagueIcon(props) {

    const {league,...iconProps} = props;

    const map = {
        "NFL": <SportsFootball {...iconProps} />,
        "NCAAF": <SportsFootball {...iconProps} />,
        "NBA": <SportsBasketball {...iconProps}/>,
        "NCAAB": <SportsBasketball {...iconProps} />,
        "WNBA": <SportsBasketball {...iconProps} />,
        "MLB": <SportsBaseball {...iconProps} />,
    }

    return map[league]
}

export const predictionWarningMonthsByLeague = {
    // Months are zero indexed so January = 0 to be used
    // to compared to dayjs().month()
    "NFL"               : [
        9
    ],
    "NBA"               : [
        10
    ],
    "NCAAF"     : [
        9
    ],
    "NCAAB"   : [
        10
    ],
    "WNBA" : [
        6
    ],
    "MLB" : [
        3,4
    ]
}

export const leagueToGlossaryIdMap = {
    'NFL' : 1,
    'NCAAF' : 2,
    'NBA' : 3,
    'NCAAB' : 4,
    'WNBA' : 5,
    'MLB' : 6,

}