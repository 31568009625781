import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid,
    Skeleton,
    Stack
} from '@mui/material';

import { TrackingSummary,ProfitChart } from './common';
import { TrackingFilterMenu } from './filters';
import { LatestBets } from './latest-bets';
import { DashboardGridItem,DashboardGridItemTitleTypography,ExportLatestBetsButton } from './common';
import { ContentLock } from '../../../../components/content-lock';

export function TrackingDashboard(
    {allBets,setAllBets,filteredBets,setFilteredBets,trackedModels,downloadLatestBetsAllowed,loading,lockContent,trackingType,setTrackingType}
){
    
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (        
        <Grid 
        direction={largeScreen ? 'row' : 'column'} 
        container
        >
            <DashboardGridItem 
            loading={loading}
            sx={{height: null}}
            stackPadding={{xs:0.75,md:1}}
            >
                <TrackingFilterMenu 
                setFilteredBets={setFilteredBets} 
                allBets={allBets} 
                trackedModels={trackedModels}
                trackingType={trackingType} 
                setTrackingType={setTrackingType}
                />
            </DashboardGridItem>
            

            <DashboardGridItem xs='auto' md={7} lg={8} loading={loading}>
                <Stack direction='column' alignItems="flex-start" justifyContent="space-between" 
                spacing={1} height="100%" width="100%" 

                // Allow for absolute elements like content lock to be contained to this element
                // Putting it here so it doens't cover filter
                sx={{position: 'relative'}}
                >
                    <TrackingSummary filteredBets={filteredBets}/>
                    <ProfitChart filteredBets={filteredBets} />

                    <ContentLock 
                    open={lockContent}
                    opaque={false}
                    />
                </Stack>
            </DashboardGridItem>


            <DashboardGridItem xs='auto' md={5} lg={4} loading={loading}>
                <DashboardGridItemTitleTypography
                actionButton={
                    // This is kind of lazy but I am only allowing exporting latest bets as csv
                    // on desktop. It doesn't seem worth it to implement additional logic for the super rare
                    // case of someone exporting a csv on their phone
                    downloadLatestBetsAllowed && largeScreen && <ExportLatestBetsButton filteredBets={filteredBets} />
                }
                >
                    Latest Bets
                </DashboardGridItemTitleTypography>
                <LatestBets
                filteredBets={filteredBets}
                filteredBetsLoading={loading}
                />
            </DashboardGridItem>
            
        </Grid>
    )
}