import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

import { teamStoreRequest,teamValidDatesRequest } from '../../../../hooks/api/team';
import { playerStoreRequest, playerValidDatesRequest } from '../../../../hooks/api/player';
import { PredictionsPopup } from './schedule';
import { TeamEventDisplay,PlayerEventDisplay } from './event-displays';
import { ErrorSnackbar } from '../../../../components/snackbars';

export function TeamsPredictionsPopup({ league, modelId, open, setOpen, setModelId = true, validateUser = true }) {

  // setModelId used to control whether we have to set the model id before navigating
  // to the predictions page

  return (
    <>
      {/* Only render popup if opened, don't pre-render */}
      {open &&
          <TeamPredictionsPopupContent 
          league={league} 
          modelId={modelId} 
          open={open} 
          setOpen={setOpen} 
          setModelId={setModelId}
          validateUser={validateUser}
          />
      }
    </>
  );
}

export function TeamPredictionsPopupContent({ league, modelId, open, setOpen, setModelId = true, validateUser = true }) {


  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [datesWithEvents, setDatesWithEvents] = useState([]);
  const [contentLocked, setContentLocked] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);
  const [error, setError] = useState(false);

  ////////////////////////////////////////////////////////
  // API calls
  ////////////////////////////////////////////////////////

  const navigate = useNavigate();

  const handleStoreResponse = (response) => {
    navigate('/team-prediction-results', { 
      state: { 
        'league': league, 
        'start_time': selectedDate.startOf('day').utc().toISOString(),
        'end_time': selectedDate.endOf('day').utc().toISOString(), 
      }
    });
  }
  const handleStoreError = (error) => {
    setError(true);
  }

  const handleRunPredictions = () => {
    setError(false);

    const params = {
      "model_id"      : modelId,
      "validate_user" : validateUser
    }

    // Set model id in session
    if (setModelId) {
      teamStoreRequest(params,handleStoreResponse,handleStoreError);
    }
    else {
      // Model already set, just navigate to results page
      navigate('/team-prediction-results', { 
        state: { 
          'league': league, 

          // Important to convert to utc AFTER getting start of day
          'start_time': selectedDate.startOf('day').utc().toISOString(),
          'end_time': selectedDate.endOf('day').utc().toISOString(), 
        }
      });
    }


  };
    
  const handleValidDatesResponse = (response) => {
        setDatesWithEvents(response.data.dates);
    }

  const handleValidDatesError = (error) => {}

  const fetchValidDates = (dayjsObj) => {
      const params = {
          league              : league,
          year                : dayjsObj.year(),
          timezone            : dayjs.tz.guess(),
          utc_offset_minutes  : dayjs().utcOffset()
      }
      teamValidDatesRequest(params,handleValidDatesResponse,handleValidDatesError);
  }

  return (
    <>
      <PredictionsPopup
      title={`Select Date | ${league}`}
      open={open}
      setOpen={setOpen}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      runDisabled={runDisabled}
      contentLocked={contentLocked}
      handleRunPredictions={handleRunPredictions}
      datesWithEvents={datesWithEvents}
      fetchValidDates={fetchValidDates}

      EventDisplay={
        <TeamEventDisplay 
        league={league}
        selectedDate={selectedDate}
        setDisabled={setRunDisabled}
        contentLocked={contentLocked}
        setContentLocked={setContentLocked}
        />
      }
      
      />
      <ErrorSnackbar 
      open={error}
      setOpen={setError}
      message={"Model has been deleted, no longer usable for predictions"}
      />
    </>
  )
}

export function PlayerPredictionsPopup({ league, position, prop, modelId, open, setOpen, setModelId = true, validateUser = true }) {

  return (
    <>
      {/* Only render popup if opened, don't pre-render */}
      {open &&
          <PlayerPredictionsPopupContent league={league} position={position} prop={prop}
            modelId={modelId} open={open} setOpen={setOpen} setModelId={setModelId} validateUser={validateUser} />
      }


    </>
  );
}

export function PlayerPredictionsPopupContent({ league, position, prop, modelId, open, setOpen, setModelId = true, validateUser = true }) {

  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [datesWithEvents, setDatesWithEvents] = useState([]);
  const [contentLocked, setContentLocked] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);
  const [error, setError] = useState(false);

  ////////////////////////////////////////////////////////
  // API calls
  ////////////////////////////////////////////////////////

  const navigate = useNavigate();

  const handleStoreResponse = (response) => {
    navigate('/player-prediction-results', { 
      state: { 
        'league': league, 
        'position': position,
        'prop': prop, 

        // Important to convert to utc AFTER getting start of day
        'start_time': selectedDate.startOf('day').utc().toISOString(),
        'end_time': selectedDate.endOf('day').utc().toISOString() 
      } 
    });
  }
  const handleStoreError = (error) => {
    setError(true);
  }

  const handleRunPredictions = () => {
    setError(false);

    const params = {
      "model_id"      : modelId,
      "validate_user" : validateUser
    }

    // Set model id in session
    if (setModelId) {
      playerStoreRequest(params,handleStoreResponse,handleStoreError);
    }
    else {
      // Model already set, just navigate to results page
      navigate('/player-prediction-results', { 
        state: { 
          'league': league, 
          'position': position,
          'prop': prop, 
          'start_time': selectedDate.startOf('day').utc().toISOString(),
          'end_time': selectedDate.endOf('day').utc().toISOString() 
        } 
      });
    }


  };

  const handleValidDatesResponse = (response) => {
    // Add to existing list of dates with events
    setDatesWithEvents(datesWithEvents.concat(response.data.dates));
  }

  const handleValidDatesError = (error) => {}

  const fetchValidDates = (dayjsObj) => {
    const params = {
        league: league,
        position: position,
        year: dayjsObj.year(),
        timezone  : dayjs.tz.guess(),
        utc_offset_minutes  : dayjs().utcOffset()
    }
    playerValidDatesRequest(params,handleValidDatesResponse,handleValidDatesError);
  }

  return (
    <>
      <PredictionsPopup
      title={`Select Date | ${league}`}
      open={open}
      setOpen={setOpen}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      runDisabled={runDisabled}
      contentLocked={contentLocked}
      handleRunPredictions={handleRunPredictions}
      datesWithEvents={datesWithEvents}
      fetchValidDates={fetchValidDates}

      EventDisplay={
        <PlayerEventDisplay 
        league={league}
        position={position}
        selectedDate={selectedDate}
        setDisabled={setRunDisabled}
        contentLocked={contentLocked}
        setContentLocked={setContentLocked}
        />
      }
      
      />
      <ErrorSnackbar 
      open={error}
      setOpen={setError}
      message={"Model has been deleted, no longer usable for predictions"}
      />
    </>
  )
}