import { useState,useEffect } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControlLabel,
    Grid,
    Input,
    Slider,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SingleSelect } from '../../../../components/menus';
import { teamHyperparametersRequest } from '../../../../hooks/api/team';
import { 
    EPOCHS_STEPS, 
    MAX_EPOCHS, 
    MAX_NODES, 
    MAX_REGULARIZATION,
    MIN_EPOCHS, 
    MIN_NODES, 
    MIN_REGULARIZATION, 
    NODES_STEPS, 
    REGULARIZATION_STEPS,
    MAX_LEARNING_RATE,
    MIN_LEARNING_RATE,
    LEARNING_RATE_STEPS,
    VALIDATION_SPLIT_STEPS,
    MIN_VALIDATION_SPLIT,
    MAX_VALIDATION_SPLIT
} from '../../../../config/models';
import { DefaultTooltip, HyperparametersTutorialButton } from '../../../../components/buttons';
import { playerHyperparametersRequest } from '../../../../hooks/api/player';
import { ContentLock } from '../../../../components/content-lock';

export function HyperParametersContent(
    {league,modelType,enabled,setEnabled,hyperparameters,setHyperparameters,dataFetched,setDataFetched,setUserPopupOpen,setSomethingWentWrong,position=""}
){
    // modelType should be only be 'team' or 'player'
    // position is an optional parameter that is used for player models only
    // These two 'un-clean' inputs allow this entire component to be common which is very nice

    const [accessAllowed,setAccessAllowed] = useState(true);

    // Stores the hyperparameters fetched from the backend and does not change
    // when users adjust the hyperparameters. Used to store the values for 
    // resetting the default.
    const [defaultHyperparameters,setDefaultHyperparameters] = useState(hyperparameters);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        // Close/Disable hyperparameters whenever league changes

        // Set to dataFetched here to make editing work. We will have fetched
        // data already so need to remain enabled
        setEnabled(dataFetched);
        setDataFetched(false);
    }, [league,position]);

    const handleResetDefaults = () => {
        setHyperparameters(defaultHyperparameters);
    }

    return (
        <Stack direction="column" spacing={1} alignItems="flex-start" sx={{p: 1}}>
        
        <Stack direction={isMobile ? "column" : "row"} alignItems="center" spacing={{xs:1,sm:2}} width="100%">
            <CustomHyperparametersSwitch 
            enabled={enabled}
            setEnabled={setEnabled} 
            setHyperparameters={setHyperparameters}
            league={league}
            modelType={modelType}
            position={position}
            dataFetched={dataFetched}
            setDataFetched={setDataFetched}
            setDefaultHyperparameters={setDefaultHyperparameters}
            setAccessAllowed={setAccessAllowed}
            setUserPopupOpen={setUserPopupOpen}
            setSomethingWentWrong={setSomethingWentWrong}
            />

            <Stack direction="row" spacing={{xs: 1, md: 2}}>
                <Button
                disabled={!enabled}
                onClick={()=> handleResetDefaults()}>
                    Reset Defaults
                </Button>

                <HyperparametersTutorialButton />
            </Stack>
        </Stack>

        {/* 
        There is some confusing logic below that requires explanation:

        - Force Open
            - The force open boolean condition will force open the minimized accordions
            when it changes from false to true only. So we are setting that to occur when either
            enable is set to true (occurs when user has proper permissions and toggles switch on) or
            when the user attempts to enable custom parameters but is NOT authorized and so the
            accessAllowed boolean flag is set to false thus evaluating forceOpen to true

        - Force Close
            - The force close boolean condition will force close the expanded accordions
            when it changes from false to true only. So we are setting this to occur in the situation
            where someone has valid permissions (accessAllowed == true) and they are switching the toggle
            off.
        */}

        <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" position="relative">
        <ContentLock open={!accessAllowed} opaque={false} zIndex={2}/>
        <Grid container columnGap={1} rowGap={1} direction={largeScreen ? "row":"column"}>
            <Grid item xs>
                <LayerBlock 
                layerNumber={1} 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters}
                enabled={enabled}
                forceOpen={enabled || !accessAllowed}
                forceClose={!enabled && accessAllowed}
                />
            </Grid>
            <Grid item xs>
                <LayerBlock 
                layerNumber={2} 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters}
                enabled={enabled}
                forceOpen={enabled || !accessAllowed}
                forceClose={!enabled && accessAllowed}
                />
            </Grid>
        </Grid>
        
        <Grid item xs width="100%">
        <GeneralBlock
        hyperparameters={hyperparameters} 
        setHyperparameters={setHyperparameters}
        enabled={enabled}
        forceOpen={enabled || !accessAllowed}
        forceClose={!enabled && accessAllowed}
        />
        </Grid>
        </Stack>
        

        </Stack>
    )
}


export function LayerBlock({hyperparameters,setHyperparameters,layerNumber,enabled,forceOpen=false,forceClose=false}){

    return (
        <HyperParametersContainer title={`Layer ${layerNumber}`} forceOpen={forceOpen} forceClose={forceClose}>
            <Stack direction="column" spacing={2} width="100%" height="100%" alignItems="center" sx={{mt: 1}}>
                    <NodeSlider 
                    hyperparameters={hyperparameters} 
                    setHyperparameters={setHyperparameters} 
                    paramKey={`layer${layerNumber}_nodes`}
                    enabled={enabled}
                    />
                    <ActivationDropdown 
                    hyperparameters={hyperparameters} 
                    setHyperparameters={setHyperparameters} 
                    paramKey={`layer${layerNumber}_activation`}
                    enabled={enabled}
                    />
                    <RegularizerBlock
                    hyperparameters={hyperparameters}
                    setHyperparameters={setHyperparameters}
                    layerNumber={layerNumber}
                    layerRegularizer={"Kernel"}
                    enabled={enabled}
                    />
                    <RegularizerBlock
                    hyperparameters={hyperparameters}
                    setHyperparameters={setHyperparameters}
                    layerNumber={layerNumber}
                    layerRegularizer={"Bias"}
                    enabled={enabled}
                    />
                    <RegularizerBlock
                    hyperparameters={hyperparameters}
                    setHyperparameters={setHyperparameters}
                    layerNumber={layerNumber}
                    layerRegularizer={"Activity"}
                    enabled={enabled}
                    />
            </Stack>
        </HyperParametersContainer>
    )
}

export function GeneralBlock({hyperparameters,setHyperparameters,enabled,forceOpen=false,forceClose=false}){

    // This won't be re-used more than once, but it simplifies to top level
    // code and makes layout organization easier

    return (
        <HyperParametersContainer title="General" forceOpen={forceOpen} forceClose={forceClose}>
            <Stack direction="column" spacing={2} width="100%" alignItems="center">
                <OptimizerDropdown 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters} 
                paramKey="optimizer"
                enabled={enabled}
                />
                <LossFunctionDropdown 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters} 
                paramKey="loss_function"
                enabled={enabled}
                />
                <EpochsSlider 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters} 
                paramKey="epochs"
                enabled={enabled}
                />
                <LearningRateSlider 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters} 
                paramKey="learning_rate"
                enabled={enabled}
                />
                <ValidationSplitSlider 
                hyperparameters={hyperparameters} 
                setHyperparameters={setHyperparameters} 
                paramKey="validation_split"
                enabled={enabled}
                />
            </Stack>
        </HyperParametersContainer>
    )
}

function RegularizerBlock({hyperparameters,setHyperparameters,layerNumber,layerRegularizer,enabled}){

    const l1_paramKey = `layer${layerNumber}_${layerRegularizer.toLowerCase()}_regularizer_l1`;
    const l2_paramKey = `layer${layerNumber}_${layerRegularizer.toLowerCase()}_regularizer_l2`;

    return (
        <Stack direction="column" spacing={1.5}>
            <Typography variant="body">{layerRegularizer} Regularization</Typography>
            <Stack direction="row" spacing={1}>
                <RegularizationInput 
                label="L1" 
                hyperparameters={hyperparameters}
                setHyperparameters={setHyperparameters}
                paramKey={l1_paramKey}
                enabled={enabled}
                />
                <RegularizationInput 
                label="L2" 
                hyperparameters={hyperparameters}
                setHyperparameters={setHyperparameters}
                paramKey={l2_paramKey}
                enabled={enabled}
                />
            </Stack>
        </Stack>

    )
}

function CustomHyperparametersSwitch(
    {
        enabled,setEnabled,setHyperparameters,league,modelType,position,dataFetched,setDataFetched,setDefaultHyperparameters,setAccessAllowed,
        setUserPopupOpen,setSomethingWentWrong
    }
){

    const handleResponse = (response) => {
        setDataFetched(true);
        setHyperparameters(response.data.hyperparameters);
        setDefaultHyperparameters(response.data.hyperparameters);
        setEnabled(true);
    }

    const handleError = (error) => {
        if (error.response.data.csrf) {
            setUserPopupOpen(true);
          } else if (error.response.status == 401 ) {
            // Unauthorized - This will put lock on content
            // to inform users to upgrade
            setAccessAllowed(false);
          }
          else {
            // Something went wrong
            setSomethingWentWrong(true);
        }

        setEnabled(false);
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            if (!dataFetched) {
                if (modelType === 'team') {
                    const params = {league: league};
                    teamHyperparametersRequest(params,handleResponse,handleError);
                } else {
                    const params = {
                        league: league,
                        position: position
                    }
                    playerHyperparametersRequest(params,handleResponse,handleError)
                }
                
            }
            else {
                // Data already fetched
                setEnabled(true);
            }
        } else {
            setEnabled(false);
        }
    }

    return (
        <FormControlLabel 
        control={
            <Switch
            checked={enabled}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Switch Custom Hyperparameters' }}
            color="secondary"
            sx={{ transform: "scale(1.1)" }}
            />
        } 
        label="Enable Custom Hyperparameters" 
        labelPlacement="start"
        slotProps={{
            typography: {
                variant: "h6"
            }
        }}
        />
    )
}

const sliderWidth = {xs: 220, md:300};
const textFieldWidth = {xs: 220, md: 250};

function NodeSlider({hyperparameters,setHyperparameters,paramKey,enabled}){

    const handleChange = (event) => {
        setHyperparameters({...hyperparameters, [paramKey] : parseInt(event.target.value)})
    }

    return (
    <Stack direction="column" spacing={1}>
        <TitleWithTooltip
        title="Nodes"
        tooltipText="The number of neurons in this layer."
        />
    
        <Stack direction="row" spacing={2} sx={{ width: sliderWidth }}>
        <Slider
            aria-label="node-slider"
            value={hyperparameters[paramKey]}
            onChange={handleChange}
            step={NODES_STEPS}
            min={MIN_NODES}
            max={MAX_NODES}
            valueLabelDisplay="auto"
            disabled={!enabled}
        />
        <Input
            value={hyperparameters[paramKey]}
            size="small"
            onChange={handleChange}
            inputProps={{
                step: NODES_STEPS,
                min: MIN_NODES,
                max: MAX_NODES,
                type: 'number',
                'aria-labelledby': 'node-slider',
            }}
            sx={{minWidth: 50}}
            disabled={!enabled}
            />
        </Stack>
    </Stack>
    )
}

function ActivationDropdown({hyperparameters,setHyperparameters,paramKey,enabled}){

    const availableActivationFns = [
        "relu"
    ]
    const availableActivationFnsDisplay = [
        "Relu"
    ]

    const handleChange = (event) => {
        setHyperparameters({...hyperparameters, [paramKey] : event.target.value})
    }

    return (
        <SingleSelect
        label="Activation Function"
        value={hyperparameters[paramKey]}
        availableValues={availableActivationFns}
        availableValuesDisplay={availableActivationFnsDisplay}
        onChangeFn={handleChange}
        enabled={enabled}
        width={textFieldWidth}
        />
    )
}

function RegularizationInput({label,hyperparameters,setHyperparameters,paramKey,enabled}){

    const handleChange = (event) => {
        // There seems to be a bug here where the target value is forced to be a string
        // so I am just casting it to a float here
        setHyperparameters({...hyperparameters, [paramKey] : parseFloat(event.target.value)})
    }

    return (
        <Stack direction="row" spacing={2}>
            <TextField
            id={paramKey}
            value={hyperparameters[paramKey]}
            onChange={handleChange}
            label={label}
            inputProps={{
                type: "number",
                step: REGULARIZATION_STEPS,
                min: MIN_REGULARIZATION,
                max: MAX_REGULARIZATION,
            }}
            slotProps={{
                inputLabel: {
                shrink: true,
                },
            }}
            sx={{minWidth: 60}}
            disabled={!enabled}
            />
        </Stack>
    )
}

function OptimizerDropdown({hyperparameters,setHyperparameters,paramKey,enabled}){

    const availableOptimizers = [
        "Adam"
    ]

    const handleChange = (event) => {
        setHyperparameters({...hyperparameters, [paramKey] : event.target.value})
    }

    return (
        <SingleSelect
        label="Optimizer"
        value={hyperparameters[paramKey]}
        availableValues={availableOptimizers}
        onChangeFn={handleChange}
        enabled={enabled}
        width={textFieldWidth}
        />
    )
}

function LossFunctionDropdown({hyperparameters,setHyperparameters,paramKey,enabled}){

    const availableLossFnsDisplay = [
        "Huber",
        "Mean Squared Error",
        "Mean Absolute Error",
        "Poisson"
    ];

    const availableLossFns = [
        "huber",
        "mean_squared_error",
        "mean_absolute_error",
        "poisson"
    ]

    const handleChange = (event) => {
        setHyperparameters({...hyperparameters, [paramKey] : event.target.value})
    }

    return (
        <SingleSelect
        label="Loss Function"
        value={hyperparameters[paramKey]}
        availableValues={availableLossFns}
        availableValuesDisplay={availableLossFnsDisplay}
        onChangeFn={handleChange}
        enabled={enabled}
        width={textFieldWidth}
        />
    )
}

function EpochsSlider({hyperparameters,setHyperparameters,paramKey,enabled}){

    const handleChange = (event) => {
        // There seems to be a bug here where the target value is forced to be a string
        // so I am just casting it to a int here
        setHyperparameters({...hyperparameters, [paramKey] : parseInt(event.target.value)})
    }

    return (
    <Stack direction="column" spacing={1}>
        <TitleWithTooltip
        title="Epochs"
        tooltipText="The number of passes through the training dataset in search of optimal parameters. 
        We use an early stopping callback to prevent overfitting, so you might not actually train for the specified number of epochs."
        />
    
        <Stack direction="row" spacing={2} sx={{ width: sliderWidth }}>
        <Slider
            aria-label="epochs-slider"
            value={hyperparameters[paramKey]}
            onChange={handleChange}
            step={EPOCHS_STEPS}
            min={MIN_EPOCHS}
            max={MAX_EPOCHS}
            valueLabelDisplay="auto"
            disabled={!enabled}
        />
        <Input
            value={hyperparameters[paramKey]}
            size="small"
            onChange={handleChange}
            inputProps={{
                step: EPOCHS_STEPS,
                min: MIN_EPOCHS,
                max: MAX_EPOCHS,
                type: 'number',
                'aria-labelledby': 'epochs-slider',
            }}
            sx={{minWidth: 50}}
            disabled={!enabled}
            />
        </Stack>
    </Stack>
    )
}

function LearningRateSlider({hyperparameters,setHyperparameters,paramKey,enabled}){

    const handleChange = (event) => {
        // There seems to be a bug here where the target value is forced to be a string
        // so I am just casting it to a float here
        setHyperparameters({...hyperparameters, [paramKey] : parseFloat(event.target.value)})
    }

    return (
    <Stack direction="column" spacing={1}>
        <TitleWithTooltip
        title="Learning Rate"
        tooltipText="The step size of gradient descent during model training. 
        A smaller learning rate can prevent a model from overfitting, but it requires more epochs to converge typically."
        />
    
        <Stack direction="row" spacing={2} sx={{ width: sliderWidth }}>
        <Slider
            aria-label="learning-rate-slider"
            value={hyperparameters[paramKey]}
            onChange={handleChange}
            step={LEARNING_RATE_STEPS}
            min={MIN_LEARNING_RATE}
            max={MAX_LEARNING_RATE}
            valueLabelDisplay="auto"
            disabled={!enabled}
        />
        <Input
            value={hyperparameters[paramKey]}
            size="small"
            onChange={handleChange}
            inputProps={{
                step: LEARNING_RATE_STEPS,
                min: MIN_LEARNING_RATE,
                max: MAX_LEARNING_RATE,
                type: 'number',
                'aria-labelledby': 'learning-rate-slider',
            }}
            sx={{minWidth: 80}}
            disabled={!enabled}
            />
        </Stack>
    </Stack>
    )
}

function ValidationSplitSlider({hyperparameters,setHyperparameters,paramKey,enabled}){

    const handleChange = (event) => {
        // There seems to be a bug here where the target value is forced to be a string
        // so I am just casting it to a float here
        setHyperparameters({...hyperparameters, [paramKey] : parseFloat(event.target.value)})
    }

    function valuetext(value) {
        return `${value*100}%`;
    }

    return (
    <Stack direction="column" spacing={1}>
        <TitleWithTooltip
        title="Validation Split"
        tooltipText="Percentage of training data to use in validation of performance. This is separate from train/test split."
        />
    
        <Stack direction="row" spacing={2} sx={{ width: sliderWidth }}>
        <Slider
            aria-label="validation-split-slider"
            value={hyperparameters[paramKey]}
            onChange={handleChange}
            step={VALIDATION_SPLIT_STEPS}
            min={MIN_VALIDATION_SPLIT}
            max={MAX_VALIDATION_SPLIT}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
            disabled={!enabled}
        />
        <Typography>
            {valuetext(hyperparameters[paramKey])}
        </Typography>
        </Stack>
    </Stack>
    )
}

function TitleWithTooltip({title,tooltipText}){

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>{title}</Typography>
            <DefaultTooltip text={tooltipText} />
        </Stack>
    )
}


function HyperParametersContainer(props){
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {title,tooltip,children,sx,forceOpen,forceClose,...otherProps} = props;

    const sidePadding = isMobile ? 1 : 2;

    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if ( forceOpen === true) {
            setExpanded(true);
        }

        if (forceClose === true) {
            setExpanded(false);
        }
    },[forceOpen,forceClose]);

    return (
        <Accordion
        disableGutters
        square={true}
        expanded={expanded}
        onChange={handleChange}
        sx={{
            width: '100%', p: sidePadding,pt: 2,pb: 2,
            borderRadius: 4, // Manually set border radius after disabling rounding using 'square' prop above
            position: 'inherit', // Hack to remove this weird line above the accordion
            backgroundImage: 'none',
            backgroundColor: 'background.paper',
            ...sx
        
        }}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
            {title}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}