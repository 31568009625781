import { ResponsiveContainer } from '../../../components/responsive';
import { SeoHelmet } from '../../../components/seo';
import { LeaderboardContainer } from '../components/container';

export default function LeaderboardPage(){
    return (
        <>
            <SeoHelmet 
            title="Model Leaderboard"
            description="View top performing models and use them to generate machine learning prediction"
            />
            <ResponsiveContainer>
                <LeaderboardContainer />
            </ResponsiveContainer>
        </>
    );
}