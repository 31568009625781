import {useState,useEffect} from 'react';

import {
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import DownloadIcon from '@mui/icons-material/Download';

import { useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

import { LineChart, Line } from 'recharts';
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { HorizontalTabMenu } from '../../../../components/menus';
import { GenericTooltip } from '../../../../components/buttons';
import { filterLatestBetsExportData, jsonToCsvBlob } from '../../../../utils/export';
import { createFileName } from "use-react-screenshot";
import { calculateNetProfits, calculateNetUnits, countBetResults, sumStakes, USD } from '../../../../utils/tracking';

function SummaryLoading({height=30}){

    return (
        <Skeleton variant="rounded" width="100%" height={height} />
    )
}

// Calculate profit from stake and american odds
// Set stake = 1 for net units
export const calculateProfit = (stake,price) => {
    let betProfit = 0;
    const americanOddsAsNumber = parseInt(price);
    if (americanOddsAsNumber > 0) {
        betProfit = stake * (americanOddsAsNumber/100);
    } else {
        betProfit = stake * (-100/ americanOddsAsNumber);
    }
    return betProfit;
}

export function TrackingSummary({filteredBets}){

    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const [loading,setLoading] = useState(true);
    const [wins,setWins] = useState(0);
    const [losses,setLosses] = useState(0);
    const [pushes,setPushes] = useState(0);
    const [profit,setProfit] = useState(0.0);
    const [profitRoi,setProfitRoi] = useState(0.0);
    const [netUnits,setNetUnits] = useState(0.0);
    const [unitsRoi,setUnitsRoi] = useState(0.0);

    useEffect(() => {
        setLoading(true);
        // Processing

        if (filteredBets.length !== 0) {
            const countResults = countBetResults(filteredBets);

            const wins = countResults.win ? countResults.win : 0;
            const losses = countResults.loss ? countResults.loss : 0;
            const pushes = countResults.push ? countResults.push : 0;
            setWins(wins);
            setLosses(losses);
            setPushes(pushes);

            // Sum profit on wins
            const netProfits = calculateNetProfits(filteredBets);

            // Sum all stakes from completed bets
            const totalStakes = sumStakes(filteredBets);

            // Sum all units staked from completed bets
            const totalStakedUnits = wins + losses + pushes;

            // Calculate net units
            const netUnits = calculateNetUnits(filteredBets);

            setProfit(Math.round( (netProfits) * 100) / 100);
            setProfitRoi( totalStakes !== 0 ? Math.round(netProfits / totalStakes * 10000) / 100 : 0 );
            setNetUnits(Math.round(netUnits*10)/10);
            setUnitsRoi( totalStakedUnits !== 0 ? Math.round(netUnits / totalStakedUnits * 10000) / 100 : 0 );
        
        }
        else {
            // Restore empty defaults if no bets in filter
            setWins(0);
            setLosses(0);
            setPushes(0);
            setProfit(0.0);
            setProfitRoi(0.0);
            setUnitsRoi(0.0);
            setNetUnits(0.0);
        }

        // Done Processing
        setLoading(false);
    }, [filteredBets]);


    return (
        <Stack 
        // Paired with width change in inner stack
        // to evenly distribute the inner items in a row for
        // all screens except extra small
        direction={extraSmallScreen ? "column" : "row"}
        width="100%"
        spacing={{xs: 0.5, sm:1, lg: 2}} 
        alignItems="center" 
        justifyContent="space-between"
        >
            
            <RecordDisplay loading={loading} wins={wins} losses={losses} pushes={pushes} />
            <Stack 
            direction="row"
            // Align with outer level stack direction switching from row to column
            // when the screen is super small. When it is not, we want these 2 items
            // to represent the same amount of space each as the RecordDisplay above,
            // thus the 200%.
            width={extraSmallScreen ? "100%" : "200%"}
            spacing={{xs: 0.5, sm:1, lg: 2}} 
            alignItems="center" 
            justifyContent="space-between"
            >
                <ProfitDisplay loading={loading} profit={profit} profitRoi={profitRoi} />
                <NetUnitsDisplay loading={loading} netUnits={netUnits} unitsRoi={unitsRoi}/>
            </Stack>
        </Stack>
     )
}

function TrackingSummaryCard(
    {loading,title,text,secondaryText,SecondaryTextIcon=null,color=null}
){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const mediumToLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

    return(
        <>
        {loading ?
        <SummaryLoading />
        :
        <Card
        sx={{
            width: '100%',
        }}
        >
            <CardHeader
            title={title}
            titleTypographyProps={{
                variant: 'body',
                sx: {fontWeight: 'light'}
            }}
            sx={{p: 1}}
            />
            <CardContent sx={{p: 0}}>
            <Stack 
            direction="row" 
            alignItems="center" 
            justifyContent="center" 
            sx={{px: 1,py: {xs: 1.5,md: 0.5} }}
            >
                <Typography 
                // Medium to Large screen is a tight fit so shrink font
                variant={mediumToLargeScreen ? "h6" : "h5"}
                sx={{
                    fontWeight: 'bold'
                }}
                >
                    {text}
                </Typography>
            </Stack>
            </CardContent>
            <CardActions 
            sx={{px: 1,py: 0.5}}>
                <Stack 
                direction="row" 
                alignItems="center" 
                spacing={0.5}
                >
                    {SecondaryTextIcon && 
                    <SecondaryTextIcon
                    fontSize={largeScreen ? "medium" : "small"}
                    sx={{color: color}} 
                    />}
                    <Typography 
                    variant={largeScreen ? "body" : "body2"} 
                    sx={{color: color}}
                    >
                        {secondaryText}
                    </Typography>
                </Stack>
                
            </CardActions>
        </Card>
        }
        </>
    )
}

function TrackingSummaryStack(
    {loading,title,text,alignItems,color}
){

    const theme = useTheme();

    // There is a responsivness issue in this region that is resolved by reducing 
    // font size
    const smallToMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <>
        {loading ?
        <SummaryLoading />
        :
        <Stack 
        direction="column"
        justifyContent="flex-start"
        alignItems={alignItems}
        height="100%"
        width="100%"
        spacing={0.5}
        >
        <Typography 
        variant="subtitle1"
        sx={{
            color: 'text.secondary'
        }}
        >
            {title}
        </Typography>
        <Typography 
        variant={smallToMediumScreen ? "body1" : "h6"}
        sx={{
            color: color
        }}
        >
            {text}
        </Typography>
        </Stack>
        }
        </>
    )
}

const getTrackingSummaryStyle = (isSuccess,isFailure) => {
    // Sometimes it will be neither, like break even, so
    // no styling

    let color = null;
    let icon = null;
    let prefix = "";
    if (isSuccess) {
        color = "secondary.main";
        icon = TrendingUpIcon;
        prefix = "+";
    }
    else if (isFailure){
        color = "error.dark";
        icon = TrendingDownIcon;
        // No prefix because '-' will be included already
    }

    return {color,icon,prefix};
}

export function RecordDisplay({loading,wins,losses,pushes,useStack=false}){

    const totalGames = wins + losses;
    const winPct = totalGames !== 0 ? Math.round(wins/totalGames*1000)/10 : 0;

    const {color,icon,prefix} = getTrackingSummaryStyle(
        (winPct >= 52.4),
        (winPct < 52.4 && losses !== 0 )
    )

    const cardText = pushes !== 0 ? 
        `${wins}-${losses}-${pushes}` : `${wins}-${losses}`;

    return (
        <>
        {useStack ?
        <TrackingSummaryStack 
        loading={loading}
        title={"Record"}
        text={cardText}
        alignItems={"flex-start"}
        color={color}
        />
        :
        <TrackingSummaryCard
        loading={loading}
        title={"Record"}
        text={cardText}
        secondaryText={`${winPct.toFixed(1)}%`}
        SecondaryTextIcon={null}
        color={color}
        />
        }
        </>
    )
}

export function NetUnitsDisplay({loading,netUnits,unitsRoi,useStack=false}){

    const {color,icon,prefix} = getTrackingSummaryStyle(
        (netUnits > 0),
        (netUnits < 0)
    )

    return (
        <>
        {useStack ?
        <TrackingSummaryStack 
        loading={loading}
        title={"Units"}
        text={`${prefix}${netUnits.toFixed(1)}u`}
        alignItems={"flex-end"}
        color={color}
        />
        :
        <TrackingSummaryCard
        loading={loading}
        title={"Units"}
        text={`${prefix}${netUnits.toFixed(1)}u`}
        secondaryText={`${unitsRoi.toFixed(1)}%`}
        SecondaryTextIcon={icon}
        color={color}
        />
        }
        </>
    )
}

export function UnitsRoiDisplay({loading,unitsRoi}){
    // unitsRoi assumed decimal 0-1

    const {color,icon,prefix} = getTrackingSummaryStyle(
        (unitsRoi > 0),
        (unitsRoi < 0)
    )

    return (
        <TrackingSummaryStack 
        loading={loading}
        title={"ROI"}
        text={`${(unitsRoi*100).toFixed(1)}%`}
        alignItems={"center"}
        color={color}
        />
    )
}

export function ProfitDisplay({loading,profit,profitRoi,useStack=false}){

    const {color,icon,prefix} = getTrackingSummaryStyle(
        (profit > 0),
        (profit < 0)
    )

    return (
        <>
        {useStack ?
        <TrackingSummaryStack 
        loading={loading}
        title={"Profit"}
        text={`${prefix}${USD.format(profit)}`}
        alignItems={"center"}
        color={color}
        />
        :
        <TrackingSummaryCard
        loading={loading}
        title={"Profit"}
        text={`${prefix}${USD.format(profit)}`}
        secondaryText={`${profitRoi.toFixed(1)}%`}
        SecondaryTextIcon={icon}
        color={color}
        />
        }
        </>
    )
}

export function ProfitChart({filteredBets}){
    
    const theme = useTheme();
    const plotAxisColor = 'white';
    const chartMargin = {
        top: 5,
        right: 30,
        left: 20,
        bottom: 20,
    };

    const [loading,setLoading] = useState(true);
    const [profitsByGameDay,setProfitsByGameDay] = useState([]);
    const [colorScheme,setColorScheme] = useState(theme.palette.success.dark);

    // Set the date format in x-axis based on length of data
    const [dateFormat,setDateFormat] = useState('MMM YY');

    useEffect(() => {
        setLoading(true);
        // Processing
        if (filteredBets.length !== 0) {
            // The logic here is we we want to calculate the net profit
            // from each day. Only add wins/losses from a day to list
            const profitsByDay = filteredBets.reduce((acc, obj) => {

                // This will create a dayjs object in user local time
                // so that game days are in user local time
                const startTime = dayjs(obj.start_time);
                const gameDay = startTime.format('YYYYMMDD');

                let betProfit = 0;
                if ( obj.result === 'win'){
                    betProfit = calculateProfit(obj.stake,obj.price);
                    acc[gameDay] = (acc[gameDay] || 0) + betProfit;
                } else if (obj.result === 'loss') {
                    betProfit = -1 * obj.stake;
                    acc[gameDay] = (acc[gameDay] || 0) + betProfit;
                }
                return acc;
            }, {});

            // Start chart at 0 profit the day before
            const profitsByDayKeys = Object.keys(profitsByDay);
            if (profitsByDayKeys.length == 0){
                // Handle no data in filter
                setProfitsByGameDay([]);
                setDateFormat('MMM YY');
            }
            else {
                const firstGameDay = profitsByDayKeys[0];
                const dayBeforeFirstGameDay = parseInt(dayjs(firstGameDay.toString()).subtract(1,'day').format('YYYYMMDD'));
                const profitsByDayArray = [{'gameDay': dayBeforeFirstGameDay , 'profit': 0}];

                // Calculate cumulative sum as the days pass 
                // Keep gameDay as an int. Iterating over keys will convert it
                // to a string
                let profitSum = 0;
                profitsByDayKeys.forEach(gameDay => {
                    profitSum += profitsByDay[gameDay];
                    profitsByDayArray.push({
                        'gameDay'  : gameDay,
                        'profit'    : profitSum
                    })
                });

                setProfitsByGameDay(profitsByDayArray);
                setColorScheme(
                    profitsByDayArray[profitsByDayArray.length - 1].profit >= 0 ?
                    theme.palette.success.dark
                    :
                    theme.palette.error.dark
                )

                // Get the delta between first and last date to determine date format
                // in days
                // References:
                // https://day.js.org/docs/en/display/difference
                // https://day.js.org/docs/en/display/format
                //
                const lastGameDay = profitsByDayKeys[profitsByDayKeys.length - 1];
                const dateRangeDeltaInDays = dayjs(lastGameDay.toString()).diff(dayjs(firstGameDay.toString()),'days');

                if (dateRangeDeltaInDays > 120) {
                    setDateFormat('MMM YY');
                }
                else if (dateRangeDeltaInDays > 30) {
                    setDateFormat('ll');
                }
                else {
                    setDateFormat('ddd MMM Do');
                }
            }
            
        }
        else {
            // Handle no data in filter
            setProfitsByGameDay([]);

            setDateFormat('MMM YY');
        }

        // Done Processing
        setLoading(false);
    }, [filteredBets]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
            <Box sx={{p: 2,backgroundColor: 'background.paper', borderRadius: 3, boxShadow: 2}}>
                <Typography>
                    {`${dayjs(label.toString()).format(dateFormat)} : ${USD.format(payload[0].value)}`}
                </Typography>
            </Box>
            );
        }
        
        return null;
    };

    return (
        <>
        {loading ?
            <Skeleton variant="rounded" width="100%" height='100%' />
        :
        <Card sx={{width: '100%',height: '100%',pt: 2}}>
            <CardContent 
            sx={{
                "&:last-child": {
                paddingBottom: 0
                },
                p: 0,
                width: '100%',
                height: '100%'
            }}>
        <Stack 
        justifyContent="center" 
        alignItems="center"
        height="100%"
        width="100%"
        >
            {profitsByGameDay.length !== 0 ?
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={profitsByGameDay}
                    margin={chartMargin}
                >
                    <XAxis 
                        dataKey="gameDay" 
                        stroke={plotAxisColor} 
                        tick={true} 
                        tickLine={{ stroke: plotAxisColor }} 
                        tickFormatter={(value) => dayjs(value.toString()).format(dateFormat)}
                        minTickGap={100}
                        tickSize={15}
                        padding={{ left: 0, right: 40 }}
                    />
                    <YAxis 
                        stroke={plotAxisColor} 
                        // Uncomment to use y-axis label
                        // label={{ value: 'Profit', angle: -90, position: 'insideLeft', stroke: plotAxisColor, offset: -10 }}
                        tick={{ fill: plotAxisColor }} tickLine={{ stroke: plotAxisColor }} 
                        tickFormatter={(value) => USD.format(value)}
                    />
                    <Tooltip 
                    content={<CustomTooltip />}
                    />
                    {/* <Legend /> */}
                    <Line 
                        type="monotone" 
                        dataKey="profit" 
                        dot={false} 
                        stroke={colorScheme} strokeWidth={3} 
                    />
                </LineChart>
            </ResponsiveContainer>
            :
            <Typography>No Data to Display</Typography>
            }
        </Stack>
        </CardContent>
        </Card>
        }
        </>
    )
}

export function DashboardGridItem(props){
    const {sx,children,loading,stackPadding,...otherProps} = props;

    return (
        <Grid item sx={{p: 0.8,height: {xs: 600, md: 650}, ...sx }} width="100%" {...otherProps}>
            {loading ?
            <Skeleton variant="rounded" width="100%" height="100%" />
            :
            <Stack direction='column' 
            alignItems="center" justifyContent="center"
            width="100%" height="100%"
            sx={{backgroundColor: 'background.paper',borderRadius: 3,boxShadow: 1, p: stackPadding ? stackPadding : {xs:1,md:2}}}
            >
                {children}
            </Stack>
            }
        </Grid>
    )
}

export function DashboardGridItemTitleTypography(props){
    const {sx,children,tooltip,actionButton,...otherProps} = props;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5} width="100%" sx={{mb: 0.75}}>
            <Typography 
            variant="h6" 
            sx={{textAlign: 'left',...sx}} 
            {...otherProps}
            >
                {children}
            </Typography>
            {tooltip && tooltip}
            {actionButton && actionButton}
        </Stack>
    )
}

export function ExportLatestBetsButton({filteredBets}){

    const [loading,setLoading] = useState(false);

    const handleDownload = (blob) => {

        try {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const a = document.createElement("a");
          a.href = url;
          a.download = createFileName('csv', 'latest-bets');
          a.click();
  
          setLoading(false);
        }
        catch {
          // Something went wrong
        }
        setLoading(false);
      }

    const handleClick = () => {
        setLoading(true);

        // Prepare CSV blob
        let rowsToExport = structuredClone(filteredBets);
        rowsToExport = filterLatestBetsExportData(rowsToExport);
        const blob = jsonToCsvBlob(rowsToExport);

        // Download blob
        handleDownload(blob)
    }

    return (
        <GenericTooltip
        title='Download as CSV'
        >
            <LoadingButton 
            variant="contained"
            loading={loading}
            size="small"
            onClick={() => handleClick()}
            >
                <DownloadIcon fontSize="small"/>
            </LoadingButton>
        </GenericTooltip>
    )
}

export function BetTypeToggle({trackingType,setTrackingType}){
  // Majorly hacky because we can't do typed enums without Typescript
  // Set the input state to Manual or Automatic. Refer to backend
  // database.defintions.TrackingTypeEnum for format

  return (
    <Stack justifyContent="center" alignItems="center">
      <HorizontalTabMenu
      value={trackingType}
      setValue={setTrackingType}
      values={['Manual','Automatic']}
      />
    </Stack>
  )
}