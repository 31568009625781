import  {useState,useEffect } from 'react';
import "react-circular-progressbar/dist/styles.css";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteIcon from '@mui/icons-material/Delete';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import dayjs from 'dayjs';

import { OverallGradeCircle } from '../../../../components/metric-displays';
import { ActionButton, GenericTooltip } from '../../../../components/buttons';
import { DropdownMenu } from '../../../../components/menus';
import { LeagueIcon } from '../../../../config/leagues';
import { calculateNetProfits, calculateNetUnits, countBetResults, sumStakes, USD } from '../../../../utils/tracking';
import { NetUnitsDisplay, ProfitDisplay, RecordDisplay } from '../tracking/common';

export function ModelCard(
    {
        modelId,modelName,league,infoDisplay,created,overallGrade,trackingEnabled,showTracking,
        filteredBets,handleEdit,handleRename,handleDelete,handleTracking,handleSummary,handlePredictions
    }
){

    const action = (
    <ModelActions 
    modelId={modelId}
    modelName={modelName}
    trackingEnabled={trackingEnabled}
    handleEdit={handleEdit}
    handleRename={handleRename} 
    handleDelete={handleDelete}
    handleTracking={handleTracking}
    />)
    
    const [trackingLoading,setTrackingLoading] = useState(true);
    const [wins,setWins] = useState(0);
    const [losses,setLosses] = useState(0);
    const [pushes,setPushes] = useState(0);
    const [profit,setProfit] = useState(0.0);
    const [profitRoi,setProfitRoi] = useState(0.0);
    const [netUnits,setNetUnits] = useState(0.0);
    const [unitsRoi,setUnitsRoi] = useState(0.0);

    useEffect(() => {
        setTrackingLoading(true);
        // Processing

        if (filteredBets.length !== 0) {
            const countResults = countBetResults(filteredBets);

            const wins = countResults.win ? countResults.win : 0;
            const losses = countResults.loss ? countResults.loss : 0;
            const pushes = countResults.push ? countResults.push : 0;
            setWins(wins);
            setLosses(losses);
            setPushes(pushes);

            // Sum profit on wins
            const netProfits = calculateNetProfits(filteredBets);

            // Sum all stakes from completed bets
            const totalStakes = sumStakes(filteredBets);

            // Sum all units staked from completed bets
            const totalStakedUnits = wins + losses + pushes;

            // Calculate net units
            const netUnits = calculateNetUnits(filteredBets);

            setProfit(Math.round( (netProfits) * 100) / 100);
            setProfitRoi( totalStakes !== 0 ? Math.round(netProfits / totalStakes * 10000) / 100 : 0 );
            setNetUnits(Math.round(netUnits*10)/10);
            setUnitsRoi( totalStakedUnits !== 0 ? Math.round(netUnits / totalStakedUnits * 10000) / 100 : 0 );
        
        }
        else {
            // Restore empty defaults if no bets in filter
            setWins(0);
            setLosses(0);
            setPushes(0);
            setProfit(0.0);
            setProfitRoi(0.0);
            setUnitsRoi(0.0);
            setNetUnits(0.0);
        }

        // Done Processing
        setTrackingLoading(false);
    }, [filteredBets]);

    return (
        
        <Card sx={{borderRadius: 2}}>
            <CardHeader
                avatar={<LeagueIcon league={league} sx={{fontSize:"2rem"}}/>}
                action={action}
                title={modelName}
                subheader={infoDisplay}
            />

            <CardContent>
                <Stack 
                direction="column" 
                justifyContent="center" 
                alignItems="center" 
                spacing={2} 
                divider={<Divider orientation="horizontal" flexItem/>}
                >
                    <Box sx={{height: 100,width: 100}}>
                        <OverallGradeCircle grade={overallGrade} />
                    </Box>

                    {showTracking &&
                    <Stack 
                    direction="row" 
                    spacing={0} 
                    width="100%"
                    >
                        <RecordDisplay 
                        loading={trackingLoading}
                        wins={wins}
                        losses={losses}
                        pushes={pushes}
                        useStack={true}
                        />
                        <ProfitDisplay 
                        loading={trackingLoading}
                        profit={profit}
                        profitRoi={profitRoi}
                        useStack={true}
                        />
                        <NetUnitsDisplay 
                        loading={trackingLoading}
                        netUnits={netUnits}
                        unitsRoi={unitsRoi}
                        useStack={true}
                        />
                    </Stack>
                    }
                </Stack>
            </CardContent>

            <CardActions >
                <Stack 
                direction="column" 
                spacing={1} 
                width="100%" 
                sx={{position: 'relative'}}
                >
                    <Stack direction="row" spacing={1} justifyContent="center" width="100%">
                        <ActionButton onClick={() => handleSummary(modelId)}>Summary</ActionButton>
                        <ActionButton onClick={() => handlePredictions(modelId)}>Predict</ActionButton>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="caption">
                            {"Created: " + dayjs(created).format("ll, LT")}
                        </Typography>
                    </Stack>

                    {trackingEnabled &&
                    <GenericTooltip
                    title="Automatic Tracking Enabled"
                    >
                        <IconButton 
                        disableRipple
                        sx={{
                            position: 'absolute',
                            bottom: -8,
                            right: 4,
                            zIndex: 5
                        }}
                        >
                            <TrackChangesIcon 
                            fontSize="medium"
                            />
                        </IconButton>
                    </GenericTooltip>
                    }
                </Stack>
            </CardActions> 
        </Card>
    )
}

function ModelActions({modelId,modelName,trackingEnabled,handleEdit,handleRename,handleDelete,handleTracking}) {

    const handleActionsClicked = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    ////////////////////////////////////////////////////////
    // Menu setup
    ////////////////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
        
    };

    return (
        <>
        <IconButton 
        aria-label="actions"
        onClick={handleActionsClicked}>
            <MoreVertIcon />
        </IconButton>

        <DropdownMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    mt: 1
                }
            }}
        >   
            <ListItem key={"Edit"} disablePadding>
                <ListItemButton onClick={()=>{handleEdit(modelId);handleClose();}}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={"Edit"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Rename"} disablePadding>
                <ListItemButton onClick={()=>{handleRename(modelId,modelName);handleClose();}}>
                <ListItemIcon>
                    <SaveAsIcon />
                </ListItemIcon>
                <ListItemText primary={"Rename"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Delete"} disablePadding>
                <ListItemButton onClick={()=>{handleDelete(modelId);handleClose();}}>
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={"Delete"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Tracking"} disablePadding>
                <ListItemButton onClick={()=>{handleTracking(modelId,trackingEnabled);handleClose();}}>
                <ListItemIcon>
                    <TrackChangesIcon />
                </ListItemIcon>
                <ListItemText primary={`${trackingEnabled ? "Untrack" : "Track"}`} />
                </ListItemButton>
            </ListItem>
        </DropdownMenu>

        </>
    )
}