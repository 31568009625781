import { FlaskClient } from "./setup";

export const accountUpdateRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('account/update',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountDetailsRequest = (handleResponse,handleError) => {
    FlaskClient.get('account/details')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountResetPasswordRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('account/reset-password',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountResetTokenValidRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('account/reset-token-valid',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountVerificationAttemptsRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('account/verification-attempts',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountVerifyGetRequest = (handleResponse,handleError) => {
    FlaskClient.get('account/verify')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountVerifyPostRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('account/verify',
      null,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountPreferencesGetRequest = (params,handleResponse,handleError) => {
    FlaskClient.get('account/preferences',
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountPreferencesPutRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('account/preferences',
    null,
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountLoginRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('account/login',
    null,
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountRegisterRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('account/register',
    null,
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountForgotPasswordRequest = (params,handleResponse,handleError) => {
    FlaskClient.post('account/forgot-password',
    null,
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountLogoutRequest = (handleResponse,handleError) => {
    FlaskClient.delete('account/logout',
    null
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountAuthenticatedRequest = (handleResponse,handleError) => {
    FlaskClient.get('account/authenticated')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountUnsubscribeRequest = (params,handleResponse,handleError) => {
    FlaskClient.put('account/unsubscribe',
    null,
    {
        params: params
    }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const accountLeaderboardAccessRequest = (handleResponse,handleError) => {
    FlaskClient.get('account/leaderboard-access')
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}
