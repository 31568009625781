import {useState,useEffect,useRef} from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Slider,
    Stack,
} from '@mui/material';

import dayjs from 'dayjs';

import { BetTypeToggle } from './common';
import { MultiSelect, SingleSelect } from '../../../../components/menus';
import { FilterMenu, SliderMenuItem } from '../../../../components/filter-menu';
import { capitalize } from '../../../../utils/general';

export function TrackingFilterMenu(
    {setFilteredBets,allBets,trackedModels,trackingType,setTrackingType}
){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    // Time Filter
    const availableTimeFilters = ['All-Time','Last 30','Last 7','Yesterday','Today'];
    const [timeFilter,setTimeFilter] = useState(availableTimeFilters[0]);
    const timeFilterFn = ({start_time}) => {
        const now = dayjs();
        switch (timeFilter) {
            case 'All-Time':
                return true;
            case 'Last 30':
                return dayjs(start_time).isAfter(now.subtract(30,'day'));
            case 'Last 7':
                return dayjs(start_time).isAfter(now.subtract(7,'day'))
            case 'Yesterday':
                return dayjs(start_time).isAfter(now.subtract(1,'day').startOf('day')) && dayjs(start_time).isBefore(now.subtract(1,'day').endOf('day'))
            case 'Today':
                return dayjs(start_time).isAfter(now.startOf('day')) && dayjs(start_time).isBefore(now.endOf('day'))
            default:
                return true;
        }
    }

    // Model Filter
    const availableModels = trackedModels.map(item => item.model_id);
    const availableModelsDisplay = trackedModels.map((items) => items.model_name);

    // Only enable (by default) those which are specified enabled=True
    const defaultEnabledModels = trackedModels.reduce((acc, item) => {
        if (item.enabled) acc.push(item.model_id);
        return acc;
    }, []);
    
    const [modelFilter,setModelFilter] = useState(defaultEnabledModels);
    const modelFilterFn = ({model_id}) => modelFilter.includes(model_id);

    ////////////////////////////////////////////////////////
    // Advanced Filters
    ////////////////////////////////////////////////////////

    // Value
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({value_bool}) => showValueBetsOnly ? value_bool : true;

    // Market. Set based on what is available in all bets.
    const validMarkets = allBets.reduce((acc, bet) => {
        if (!acc.includes(bet.market)) acc.push(bet.market);
        return acc;
    }, []);
    const validMarketsDisplay = validMarkets.map(validMarket => capitalize(validMarket));
    const [marketSelection,setMarketSelection] = useState(validMarkets);
    const marketFilterFn = ({market}) => marketSelection.includes(market);

    // Bet Type within market
    // This is one of the only hardcoded elements of this filter. Need to add
    // an option here manually for new markets or bet types.
    const allValidBetTypesMap = {
        'favorite':'spreads','underdog':'spreads','over':'totals','under':'totals'
    };
    const validBetTypes = Object.keys(allValidBetTypesMap);
    const [betTypeSelection,setBetTypeSelection] = useState(validBetTypes);
    const betTypeFilterFn = ({market,pick,point}) => {

        if (market === 'spreads') {
            // Pick is away/home not favorite/underdog so logic required
            // to determine if pick is favorite or underdog just using point
            const pickType = point < 0 ? 'favorite' : 'underdog'
            return betTypeSelection.includes(pickType);
        }
        else if (market === 'totals') {
            // Pick matches bet type formatting
            return betTypeSelection.includes(pick);
        }
        else {
            // Should never occur
            return false;
        }
    }

    // Delta (%)
    const minPercentageDelta = 0;
    const maxPercentageDelta = 1; // 100%
    const [deltaPercentage,setDeltaPercentage] = useState([minPercentageDelta,maxPercentageDelta]);
    const deltaPercentageFormat = (value) =>
        value === maxPercentageDelta ? `${(maxPercentageDelta*100).toFixed(0)}+%` : `${(value*100).toFixed(0)}%`;
    const deltaPercentageFilterFn = ({predicted_point,point}) => {

        // Prevent divide by zero by just setting point to 1 for rough
        // estimate of delta
        const safePoint = point === 0 ? 1 : point;

        const betDeltaPercentage = Math.abs((predicted_point - point) / safePoint);
        if (deltaPercentage[1] === maxPercentageDelta) {
            // If the max is 100%, there will be deltas beyond that
            // so 100% is being qualified as 100+%
            return deltaPercentage[0] <= betDeltaPercentage;
        }
        else {
            // Find bet deltas within range
            return deltaPercentage[0] <= betDeltaPercentage && betDeltaPercentage <= deltaPercentage[1];
        }
    };

    // Delta (mag)
    const minMagnitudeDelta = 0;
    const maxMagnitudeDelta = 20;
    const [deltaMagnitude,setDeltaMagnitude] = useState([minMagnitudeDelta,maxMagnitudeDelta]);
    const deltaMagnitudeFormat = (value) =>
        value === maxMagnitudeDelta ? `${(maxMagnitudeDelta).toFixed(1)}+` : `${(value).toFixed(1)}`;
    const deltaMagnitudeFilterFn = ({predicted_point,point}) => {
        const betDeltaMagnitude = Math.abs(predicted_point - point);
        if (deltaMagnitude[1] === maxMagnitudeDelta) {
            // If upper end equals max, there will be deltas beyond that
            // so max is being qualified as max+
            return deltaMagnitude[0] <= betDeltaMagnitude;
        }
        else {
            // Find bet deltas within range
            return deltaMagnitude[0] <= betDeltaMagnitude && betDeltaMagnitude <= deltaMagnitude[1];
        }
    };
    
    useEffect(() => {
        if (allBets.length !== 0) {
            let filteredData = allBets.slice().filter(timeFilterFn);
            filteredData = filteredData.filter(modelFilterFn);
            filteredData = filteredData.filter(valueFilterFn);
            filteredData = filteredData.filter(marketFilterFn);
            filteredData = filteredData.filter(betTypeFilterFn);
            filteredData = filteredData.filter(deltaPercentageFilterFn);
            filteredData = filteredData.filter(deltaMagnitudeFilterFn);
            setFilteredBets(filteredData);
        }
    },[timeFilter,modelFilter,showValueBetsOnly,marketSelection,betTypeSelection,deltaPercentage,deltaMagnitude,allBets]);

    const menuWidth = largeScreen ? 150 : 125;

    return (
        <HorizontalScrollStackWithArrows>

            <BetTypeToggle
            trackingType={trackingType} 
            setTrackingType={setTrackingType} 
            />
            
            <SingleSelect
            label={null}
            value={timeFilter}
            availableValues={availableTimeFilters}
            onChangeFn={(event) => setTimeFilter(event.target.value)}
            width={menuWidth}
            size='small'
            />
            
            <MultiSelect
            label={"Models"}
            values={modelFilter}
            setValues={setModelFilter}
            availableValues={availableModels}
            availableValuesDisplay={availableModelsDisplay}
            width={menuWidth}
            size='small'
            />

            <FormControlLabel
            control={
            <Checkbox
                checked={showValueBetsOnly}
                onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
            />
            }
            label="Value Only"
            sx={{ 
                width: menuWidth, m: 0, backgroundColor: 'background.paper',
                border: showValueBetsOnly ? 1.5 : 1, borderRadius: 1, borderColor: showValueBetsOnly ? 'primary.main' : '#57636f',
                '&:hover': { 
                    border: 1.5
                }
            }}
            />

            <MultiSelect
            label={"Markets"}
            values={marketSelection}
            setValues={setMarketSelection}
            availableValues={validMarkets}
            availableValuesDisplay={validMarketsDisplay}
            size='small'
            width={menuWidth}
            />

            <MultiSelect
            label={"Bet Type"}
            values={betTypeSelection}
            setValues={setBetTypeSelection}

            // Setting available values this way so that bet types for de-selected markets
            // do not show such as Favorites for Totals.
            availableValues={validBetTypes.filter(betType => marketSelection.includes(allValidBetTypesMap[betType]))}
            availableValuesDisplay={validBetTypes.filter(betType => marketSelection.includes(allValidBetTypesMap[betType])).map(validBetType => capitalize(validBetType))}
            size='small'
            width={menuWidth}
            />

            <FilterMenu 
            name="Advanced"
            variant="outlined"
            >
                <SliderMenuItem
                title={`Delta (Pct): ${deltaPercentageFormat(deltaPercentage[0])} - ${deltaPercentageFormat(deltaPercentage[1])}`}
                tooltipText="The percentage difference between the model prediction and the sportsbooks line of the bet.
                Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
                >
                    <Slider
                        getAriaLabel={()=>"delta-percentage-split-slider"}
                        value={deltaPercentage}
                        onChange={(event) => setDeltaPercentage(event.target.value)}
                        step={0.01}
                        min={minPercentageDelta}
                        max={maxPercentageDelta}
                        valueLabelDisplay="off"
                        valueLabelFormat={deltaPercentageFormat}
                    />
                </SliderMenuItem>

                <SliderMenuItem
                title={`Delta (Abs): ${deltaMagnitudeFormat(deltaMagnitude[0])} - ${deltaMagnitudeFormat(deltaMagnitude[1])}`}
                tooltipText="The magnitude difference between the model prediction and the sportsbooks line of the bet.
                Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
                >
                    <Slider
                        getAriaLabel={()=>"delta-magnitude-split-slider"}
                        value={deltaMagnitude}
                        onChange={(event) => setDeltaMagnitude(event.target.value)}
                        step={0.5}
                        min={minMagnitudeDelta}
                        max={maxMagnitudeDelta}
                        valueLabelDisplay="off"
                        valueLabelFormat={deltaMagnitudeFormat}
                            
                    />
                </SliderMenuItem>

            </FilterMenu>
        </HorizontalScrollStackWithArrows>
    )
}

export function HorizontalScrollStackWithArrows(props){

    const {children,px,py,...otherProps} = props;

    // const theme = useTheme();
    // const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const scrollRef = useRef(null);
    const scrollPixelAmount = 200;

    const scroll = (direction) => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({
          left: direction === 'left' ? -1*scrollPixelAmount : scrollPixelAmount,
          behavior: 'smooth',
        });
      }
    };

    const scrollButtonProps = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        height: '100%',
        '&:hover': {
            boxShadow: 'none',
            backdropFilter: 'blur(5px)',
        },
    }
  
    return (
      <Stack 
        justifyContent="center"
        sx={{ 
            position: 'relative', width: '100%', overflowX: 'hidden', 
            px: px!= null ? px : 1, py: py,
            '&::-webkit-scrollbar': { display: 'none' }, // Hide default scrollbar
        }}
        >
        {/* Left Arrow */}
        {/* {largeScreen &&
        <Button
          onClick={() => scroll('left')}
          size="small"
          disableRipple
          sx={{
            ...scrollButtonProps,
            left: 0,
          }}
        >
          <ChevronLeft fontSize="large"/>
        </Button>
        } */}
  
        {/* Scrollable Stack */}
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            flexShrink: 0,
            width: '100%',
            height: '100%',
            '&::-webkit-scrollbar': { display: 'none' }, // Hide default scrollbar
          }}
        >
          <Stack
          direction="row" 
          spacing={{xs:0.7,md:1}} 
          height="100%"
          width="100%"
          alignItems="center"
          sx={{py: 0.2}}
          >
            {children.map((item,index)=>
            <Stack 
            alignItems="center" 
            justifyContent="center" 
            key={index}
            >
                {item}
            </Stack>
            )}
          </Stack>
        </Box>
  
        {/* Right Arrow */}
        {/* {largeScreen &&
        <Button
          onClick={() => scroll('right')}
          size="small"
          disableRipple
          sx={{
            ...scrollButtonProps,
            right: 0,
          }}
        >
          <ChevronRight fontSize="large"/>
        </Button>
        } */}

      </Stack>
    );
};