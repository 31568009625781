
import {useMemo,useState,useEffect} from 'react';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Slider,
  Stack,
  Typography
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoadingButton from '@mui/lab/LoadingButton';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

const EST = 'America/New_York';

import html2canvas from 'html2canvas';

import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    tableHeight,
    validSportsbooks 
} from '../../../../components/table';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { BestOddsTotalBlockDisplay, TotalBlockDisplay } from '../common/prediction-displays';
import { FilterMenu, FilterMenuItem, SliderMenuItem, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';
import { ExportPredictionsButton, ExportPredictionsPopup } from '../common/export-predictions';
import { ResponsiveIconStartButton } from '../../../../components/buttons';
import { WarningSnackbar } from '../../../../components/snackbars';
import { PROPS_WITHOUT_ODDS } from '../../../../config/models';
import { blobToShareData, filterPredictionsExportData, jsonToCsvBlob } from '../../../../utils/export';
import { availablePredictionExportDataFormats, defaultPredictionExportDataFormat } from '../../../../config/export';
import { DesktopBettingDrawer, MobileBettingDrawer } from '../common/betting-popup';

function PlayerPredictionResultsFilter({
    prop,largeScreen,sportsbookSelection,setSportsbookSelection,
    predictionData,setFilteredPredictionsData
}){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Sportsbook filter is tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [hidePlayersWithoutodds, setHidePlayersWithoutodds] = useState(
        !PROPS_WITHOUT_ODDS.includes(prop)
    );
    const oddsExistFilterFn = ({odds_exist}) => hidePlayersWithoutodds ? odds_exist : true;

    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => {
        if (!showValueBetsOnly) {
            return true;
        }
        else {
            return predicted_odds_values['over']['value_bool'] || predicted_odds_values['under']['value_bool'];
        }
    }

    // Totals Market Filter
    const availableTotalTypes = ["All","Over","Under"];
    const [totalType,setTotalType] = useState("All");
    const totalTypeFilterFn = ({predicted_odds,best_odds}) => {
        if (totalType === 'All') {
            return true;
        }
        else {
            if (totalType === 'Over') {
                const totalTypeFilter = 'over';
                return predicted_odds[totalTypeFilter].point > best_odds[totalTypeFilter].consensus
            } else {
                // Unders
                const totalTypeFilter = 'under';
                return predicted_odds[totalTypeFilter].point < best_odds[totalTypeFilter].consensus
            }
        }
    }

    // Future Events filter
    // Only show events that haven't started yet if enabled
    const [showFutureEventsOnly,setShowFutureEventsOnly] = useState(false);
    const futureEventsFilterFn = ({matchup}) => 
        showFutureEventsOnly && matchup.start_time != null ? dayjs(matchup.start_time) > dayjs() : true;

    // Delta (%)
    const minPercentageDelta = 0;
    const maxPercentageDelta = 1; // 100%
    const [deltaPercentage,setDeltaPercentage] = useState([minPercentageDelta,maxPercentageDelta]);
    const deltaPercentageFormat = (value) =>
        value === maxPercentageDelta ? `${(maxPercentageDelta*100).toFixed(0)}+%` : `${(value*100).toFixed(0)}%`;
    const deltaPercentageFilterFn = ({predicted_odds,best_odds,matchup}) => {

        // Fairly complex but since each row is 2 bets (each side), we need to determine
        // the pick and then look at that prediction relative to its respective point value
        // within the market
        const pick = matchup.pick;

        if (pick == null) {
            // No odds, can't filter out
            return true;
        }

        // Odds data must exist because pick was not null
        const predicted_point = predicted_odds[pick]['point'];
        const point = best_odds[pick]['point'];

        // Prevent divide by zero by just setting point to 1 for rough
        // estimate of delta
        const safePoint = point === 0 ? 1 : point;

        const betDeltaPercentage = Math.abs((predicted_point - point) / safePoint);
        if (deltaPercentage[1] === maxPercentageDelta) {
            // If the max is 100%, there will be deltas beyond that
            // so 100% is being qualified as 100+%
            return deltaPercentage[0] <= betDeltaPercentage;
        }
        else {
            // Find bet deltas within range
            return deltaPercentage[0] <= betDeltaPercentage && betDeltaPercentage <= deltaPercentage[1];
        }
    };

    // Delta (mag)
    const minMagnitudeDelta = 0;
    const maxMagnitudeDelta = 20;
    const [deltaMagnitude,setDeltaMagnitude] = useState([minMagnitudeDelta,maxMagnitudeDelta]);
    const deltaMagnitudeFormat = (value) =>
        value === maxMagnitudeDelta ? `${(maxMagnitudeDelta).toFixed(1)}+` : `${(value).toFixed(1)}`;
    const deltaMagnitudeFilterFn = ({predicted_odds,best_odds,matchup}) => {

        // Fairly complex but since each row is 2 bets (each side), we need to determine
        // the pick and then look at that prediction relative to its respective point value
        // within the market
        const pick = matchup.pick;

        if (pick == null) {
            // No odds, can't filter out
            return true;
        }

        // Odds data must exist because pick was not null
        const predicted_point = predicted_odds[pick]['point'];
        const point = best_odds[pick]['point'];
        
        const betDeltaMagnitude = Math.abs(predicted_point - point);
        if (deltaMagnitude[1] === maxMagnitudeDelta) {
            // If upper end equals max, there will be deltas beyond that
            // so max is being qualified as max+
            return deltaMagnitude[0] <= betDeltaMagnitude;
        }
        else {
            // Find bet deltas within range
            return deltaMagnitude[0] <= betDeltaMagnitude && betDeltaMagnitude <= deltaMagnitude[1];
        }
    };

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = structuredClone(predictionData).filter(oddsExistFilterFn);
            filteredData = filteredData.filter(valueFilterFn);
            filteredData = filteredData.filter(totalTypeFilterFn);
            filteredData = filteredData.filter(futureEventsFilterFn);
            filteredData = filteredData.filter(deltaPercentageFilterFn);
            filteredData = filteredData.filter(deltaMagnitudeFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[predictionData,hidePlayersWithoutodds,showValueBetsOnly,totalType,showFutureEventsOnly,deltaPercentage,deltaMagnitude]);

    return (
        <FilterMenu>

            {/* The below items are only shown on small screens */}
            {!largeScreen &&
            // Don't give users this option until the screen is smaller
            <FilterMenuItem>
                <SingleSelect 
                label="Sportsbook"
                value={sportsbookSelection}
                availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                onChangeFn={(event)=> setSportsbookSelection(event.target.value)}
                size='small'
                />
            </FilterMenuItem>
            }

            {/* The below items are always shown in the filter */}
            <FilterMenuItem>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hidePlayersWithoutodds}
                            onChange={(event) => setHidePlayersWithoutodds(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                label="Only show players with odds"
                />
            </FilterMenuItem>
            <FilterMenuItem>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={showValueBetsOnly}
                        onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    }
                label="Show value bets only"
                />
            </FilterMenuItem>

            <FilterMenuItem>
                <SingleSelect
                label="Bet Type"
                value={totalType}
                onChangeFn={(event) => setTotalType(event.target.value)}
                availableValues={availableTotalTypes}
                size='small'
                />
            </FilterMenuItem>

            <FilterMenuItem>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={showFutureEventsOnly}
                        onChange={(event) => setShowFutureEventsOnly(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    }
                label="Show future events only"
                />
            </FilterMenuItem>

            <SliderMenuItem
            title={`Delta (Pct): ${deltaPercentageFormat(deltaPercentage[0])} - ${deltaPercentageFormat(deltaPercentage[1])}`}
            tooltipText="The percentage difference between the model prediction and the sportsbooks line of the bet.
            Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
            >
                <Slider
                    getAriaLabel={()=>"delta-percentage-split-slider"}
                    value={deltaPercentage}
                    onChange={(event) => setDeltaPercentage(event.target.value)}
                    step={0.01}
                    min={minPercentageDelta}
                    max={maxPercentageDelta}
                    valueLabelDisplay="off"
                    valueLabelFormat={deltaPercentageFormat}
                />
            </SliderMenuItem>

            <SliderMenuItem
            title={`Delta (Abs): ${deltaMagnitudeFormat(deltaMagnitude[0])} - ${deltaMagnitudeFormat(deltaMagnitude[1])}`}
            tooltipText="The magnitude difference between the model prediction and the sportsbooks line of the bet.
            Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
            >
                <Slider
                    getAriaLabel={()=>"delta-magnitude-split-slider"}
                    value={deltaMagnitude}
                    onChange={(event) => setDeltaMagnitude(event.target.value)}
                    step={0.5}
                    min={minMagnitudeDelta}
                    max={maxMagnitudeDelta}
                    valueLabelDisplay="off"
                    valueLabelFormat={deltaMagnitudeFormat}
                        
                />
            </SliderMenuItem>
        </FilterMenu>
    )
}

function PlayerPredictionResultsSortBy({filteredData,setFilteredData}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values.max_value,b.predicted_odds_values.max_value)
            },
            {
                title: "Earliest",
                sortFn: (a,b) => 
                    ascendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
            },
            {
                title: "Latest",
                sortFn: (a,b) => 
                    descendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}

export function PlayerPredictionResultsTable({league,position,prop,startTime,endTime,predictionData,units,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const screenTooSmallForShare = useMediaQuery('(max-width:350px)');

    const columnVisibilityDict = {
        "best_odds" : true,
        "fanduel"   : true,
        "draftkings" : true,
        "betmgm"    : true,
        "espnbet"    : true,
        "betrivers"    : true,
        "betonlineag"    : true,
        "bovada"     : true,
        "ballybet" : true,
        "hardrockbet" : true,
        "mybookieag" : true,
        "caesars"   : true,
        "betus"  : true,
        "lowvigag"  : true,
        "betparx"  : true,
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////

    // Sort by start time on initial set
    const [filteredPredictionsData,setFilteredPredictionsData] = useState(
        predictionData.sort( (a,b) =>
            ascendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
        )
    );

    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict);

    // Share States - I know there is a lot
    const [tableRef,setTableRef] = useState(null);
    const [enableExport,setEnableExport] = useState(false);
    const [rowSelection,setRowSelection] = useState({});
    const [enableRowSelection,setEnableRowSelection] = useState(false);
    const [exportLoading,setExportLoading] = useState(false);
    const [sharePopupOpen,setSharePopupOpen] = useState(false);
    const [exportDataBlob,setExportDataBlob] = useState(null);
    const [warningSnackbarOpen,setWarningSnackbarOpen] = useState(false);
    const [preShareFilteredPredictionsData,setPreShareFilteredPredictionsData] = useState([]);
    const [exportDataFormat,setExportDataFormat] = useState(defaultPredictionExportDataFormat)

    // Bet Tracking states
    const [pendingBets,setPendingBets] = useState([]);
    const [betDrawerOpen,setBetDrawerOpen] = useState(false);

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
        () => [
          {
              // Set the row value for search, display is overriden by Cell below
              accessorFn: (row) => `${row.matchup.player.first_name} ${row.matchup.player.last_name}`,
              id: 'players',
              header: null,
              size: largeScreen ? 270 : 150,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Cell: ({ row }) =>
                  <>
                      <PlayerDisplay matchup={row.original.matchup} units={units} 
                      league={league} largeScreen={largeScreen}
                      />
                  </>
          },
          {
              accessorFn: (row) => row.predicted_odds, 
              id: 'predicted_prop',
              header: 'Prediction',
              size: oddsColumnWidth,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnPinning: true,
              Header: ({ column }) => (
                    <>
                        <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                    </>
                ),
              Cell: ({ row }) =>
                  <>
                      <TotalBlockDisplay totals={row.original.predicted_odds}
                            // These have to be str indexed for some reason
                            overHasValue={row.original.predicted_odds_values.over['value_bool']}
                            underHasValue={row.original.predicted_odds_values.under['value_bool']}
                      />
                  </>
          },
          {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay 
                    best_odds_totals={row.original.best_odds} 
                    overBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        position: position,
                        prop: prop,
                        sportsbook: 'best',
                        market: 'totals',
                        pick: 'over',
                        stake: 1.0
                    },...pendingBets])}
                    underBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        position: position,
                        prop: prop,
                        sportsbook: 'best',
                        market: 'totals',
                        pick: 'under',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
        },
          ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display], 
            id: sportsbook.key,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display]} 
                    bestSportsbookOver={row.original.best_odds.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.under.sportsbooks.includes(sportsbook.display)}
                    overBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        position: position,
                        prop: prop,
                        sportsbook: sportsbook.display,
                        market: 'totals',
                        pick: 'over',
                        stake: 1.0
                    },...pendingBets])}
                    underBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        position: position,
                        prop: prop,
                        sportsbook: sportsbook.display,
                        market: 'totals',
                        pick: 'under',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
            )
            }
            ))
  
          ],
          [units,largeScreen]
    );

    const updateColumnVisibilityResponsive = () => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict);
        }
        else {
            let localColumnVisibility = columnVisibilityDict;
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    useEffect(() => {
        updateColumnVisibilityResponsive();
    }, [largeScreen,sportsbookSelection]);

    ////////////////////////////////////////////////////////
    // Share functions
    ////////////////////////////////////////////////////////

    const setupExportData = (table) => {
        // Sets up table to allow users to select the rows they
        // want to export

        if (screenTooSmallForShare) {
            setWarningSnackbarOpen(true);
            return;
        }

        setTableRef(table);
        setEnableExport(true);
        setPreShareFilteredPredictionsData(filteredPredictionsData);
        setEnableRowSelection(true);
    }

    const exportData = async (shareData) => {
        // Only use Web Share API when mobile
        if (navigator.share !== null && !largeScreen) {
            try {
              await navigator
                .share(shareData)
                .then(() => {
                  setEnableRowSelection(true);
                  setFilteredPredictionsData(preShareFilteredPredictionsData);
                  setExportLoading(false);
              });
            } catch (error) {
              // Web Share Api exited with bad status. This occurs
              // when a share is cancelled though
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
            }
        } else {
              // fallback code for unsupported browsers and desktop browsers
              setSharePopupOpen(true);
  
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
        }

    }

    const sharePng = async (canvas) => {

        const imageUrl = canvas.toDataURL();
        const blob = await (await fetch(imageUrl)).blob();
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.png');

        exportData(shareData);
        
    };

    const shareCsv = async (selectedRowIndexes) => {

        // Convert filtered predictions data into a csv
        let rowsToExport = structuredClone(filteredPredictionsData).filter((_,index) => selectedRowIndexes.includes(index.toString()));

        // Remove some data before export to make data consistent length
        // and to protect some of the information from being easily exported.
        rowsToExport = filterPredictionsExportData(rowsToExport,false);

        const blob = jsonToCsvBlob(rowsToExport)
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.csv');

        exportData(shareData);
    };

    const handleExportData = () => {
        // Do not try to export nothing
        if (Object.keys(rowSelection).length == 0) {
            return;
        }

        setExportLoading(true);

        // Set up table for canvas capture
        // Filter to show only selected rows and disable row selection to hide that column
        // and styling
        const selectedRowIndexes = Object.keys(rowSelection);
        setFilteredPredictionsData(filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString())));
        setRowSelection({});
        setEnableRowSelection(false);

        switch (exportDataFormat) {
            case "png":
                // Wait while canvas setup is rendering
                setTimeout(() => {

                const ref = tableRef.refs.tableContainerRef.current;
                
                html2canvas(ref,{
                    height: document.outerHeight,
                    windowHeight: document.outerHeight,
                    scale: 5,
                    useCORS: true
                }).then(canvas => {
                    sharePng(canvas);
                });

                }, 1000);
                break;

            case "csv":
                shareCsv(selectedRowIndexes);
                break;

            default:
                // Should never occur
                break;

        }
        
    }

    const handleCancelExportData = () => {
        setRowSelection({});
        setEnableExport(false);
        setEnableRowSelection(false);
    }

    ////////////////////////////////////////////////////////
    // Bet Tracking functions
    ////////////////////////////////////////////////////////

    useEffect(() => {
        // The behavior will differ here depending on desktop and mobile
        // For desktop, we always want to open the drawer when a bet is place
        // For mobile, we only want to FORCE open it if pendingBets length is 1.
        // This only occurs when the first bet is added and we want to show the user
        // their bet slip. When we go from a length of 2 to 1, it won't matter because
        // the bet slip is already open.
        //
        // So the second part of the logic in laymans terms is that if on mobile, we will
        // only FORCE open the drawer when the length is 1 or we will keep it open if the drawer
        // is already open
        setBetDrawerOpen(pendingBets.length !== 0 && (largeScreen || (pendingBets.length === 1 || betDrawerOpen)));
    }, [pendingBets]);

    return (
        <>
        <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
            <BasePredictionResultsMRT
                columns={columns}
                data={filteredPredictionsData}

                loading={loading}
                theme={theme}
                largeScreen={largeScreen}
                height={enableExport ? 'none' : tableHeight}

                // Top Toolbar custom actions
                renderTopToolbarCustomActions={({ table }) => {

                    return (
                    <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                        <ExportPredictionsPopup 
                        open={sharePopupOpen} 
                        setOpen={setSharePopupOpen}
                        blob={exportDataBlob}
                        format={exportDataFormat}
                        />
                        <WarningSnackbar 
                        open={warningSnackbarOpen}
                        setOpen={setWarningSnackbarOpen}
                        message={"Screen too small to share predictions"} 
                        />
                        <Stack direction="row" spacing={1}>
                            {enableExport ?
                            <>
                            <LoadingButton
                            loading={exportLoading}
                            startIcon={<ShareIcon />}
                            onClick={handleExportData} 
                            variant="contained"
                            sx={{backgroundColor: "success.dark"}}
                            >
                                Share
                            </LoadingButton>
                            <SingleSelect
                            label="format"
                            value={exportDataFormat}
                            availableValues={availablePredictionExportDataFormats}
                            onChangeFn={(event) => setExportDataFormat(event.target.value)}
                            width={80}
                            size='small'
                            />
                            <ResponsiveIconStartButton
                            text="Cancel"
                            icon={<CloseIcon />}
                            onClick={handleCancelExportData} 
                            sx={{backgroundColor: "error.dark"}}
                            />
                            </>
                            :
                            <>
                            <PlayerPredictionResultsFilter
                                prop={prop}
                                largeScreen={largeScreen}
                                sportsbookSelection={sportsbookSelection}
                                setSportsbookSelection={setSportsbookSelection}
                                predictionData={predictionData}
                                setFilteredPredictionsData={setFilteredPredictionsData}
                            />
                            <PlayerPredictionResultsSortBy 
                                filteredData={filteredPredictionsData}
                                setFilteredData={setFilteredPredictionsData}
                            />
                            <ExportPredictionsButton setupExportData={() => setupExportData(table)} />

                            {largeScreen &&
                            <ResponsiveIconStartButton
                            text="Track" 
                            onClick={()=>setBetDrawerOpen(true)}
                            icon={<AssessmentIcon/>}
                            />
                            }
                            </>
                            }
                        </Stack>
                    </Box>
                    );
                }}

                state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true, rowSelection }}
            
                onColumnVisibilityChange={ setColumnVisibility }

                // Column Pinning
                enableColumnPinning={false}
                layoutMode='grid-no-grow'
                initialState= {{
                    columnPinning: { left: ['mrt-row-select','players','predicted_prop'] },
                    showGlobalFilter: true
                }}

                // Row selection
                enableRowSelection={enableRowSelection}
                onRowSelectionChange={setRowSelection}

            /> {/* End of table definition */}

            {largeScreen ?
            <>
            <DesktopBettingDrawer 
            open={betDrawerOpen} 
            setOpen={setBetDrawerOpen} 
            pendingBets={pendingBets} 
            setPendingBets={setPendingBets}
            modelType={"player"}
            />
            </>
            :
            <MobileBettingDrawer
            open={betDrawerOpen} 
            setOpen={setBetDrawerOpen} 
            pendingBets={pendingBets} 
            setPendingBets={setPendingBets}
            modelType={"player"}
            />
            }
        </Box>    
        </>
        )
}

function PlayerDisplay({matchup,units,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;
    const gamesRelativeWidth = 70;

    return (
        <Stack 
        direction="row" 
        height="100%" 
        width="100%" 
        justifyContent="center" 
        alignItems="center" 
        spacing={1}
        divider={<Divider orientation="vertical" />}
        >
            {largeScreen && 
            <Stack 
            direction="column" 
            width={`${100 - gamesRelativeWidth}%`}
            height="100%" 
            justifyContent="center" 
            alignItems="center"
            >
                <Typography variant="subtitle" sx={{color: 'text.secondary'}}>
                    {dayjs(matchup.start_time).format('MMM Do')}
                </Typography>
                <Typography variant="subtitle" sx={{color: 'text.secondary'}}>
                    {dayjs(matchup.start_time).format('LT')}
                </Typography>
            </Stack>
            }
            <Stack direction="column" height="100%" width={largeScreen ? `${gamesRelativeWidth}%` : '100%'} justifyContent="center" spacing={1}>
                <Stack height="100%" justifyContent="center">

                        <Stack direction="column" justifyContent="center" spacing={1}>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <TeamLogo team={`${matchup.team.location} ${matchup.team.nickname}`} league={league} width={logoWidth}/>
                                
                                <Typography variant={typographyVariant} >
                                    { `${largeScreen ? matchup.player.first_name : matchup.player.first_name[0]+"."} ${matchup.player.last_name}`}
                                </Typography>
                                    
                            </Stack>
                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <Box sx={{width: logoWidth}}></Box>
                                <Typography variant={typographyVariant}>
                                        {matchup.player.prediction + " " + units}
                                </Typography>
                            </Stack>

                            <Stack direction="row" spacing={{xs:0.5,sm:1}} justifyContent="flex-start" alignItems="center">
                                <Box sx={{width: logoWidth}}></Box>
                                <Typography variant="subtitle" sx={{color: 'text.secondary'}} >
                                    vs {matchup.opponent.abbreviation}
                                </Typography>
                            </Stack>
                        </Stack>
                        
                    
                </Stack>
            </Stack>
        </Stack>
    )
}



