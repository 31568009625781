
import {useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Slider,
  Stack,
  Typography
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoadingButton from '@mui/lab/LoadingButton';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

import html2canvas from 'html2canvas';

import { TeamLogo } from '../../../../components/graphics/team-logo';
import { ResponsiveSportsbookLogo } from '../../../../components/graphics/sportsbook-logo';

import { 
    TotalBlockDisplay,SpreadBlockDisplay, 
    BestOddsTotalBlockDisplay, 
    BestOddsSpreadBlockDisplay 
} from '../common/prediction-displays';

import { 
    BasePredictionResultsMRT,
    DesktopOddsColumnWidth,
    MobileOddsColumnWidth,
    tableHeight,
    validMarkets,
    validSportsbooks 
} from '../../../../components/table';

import { FilterMenu, FilterMenuItem, SliderMenuItem, SortByMenu } from '../../../../components/filter-menu';
import { SingleSelect } from '../../../../components/menus';
import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { ExportPredictionsButton, ExportPredictionsPopup } from '../common/export-predictions';
import { ResponsiveIconStartButton } from '../../../../components/buttons';
import { WarningSnackbar } from '../../../../components/snackbars';
import { blobToShareData, filterPredictionsExportData, jsonToCsvBlob } from '../../../../utils/export';
import { availablePredictionExportDataFormats, defaultPredictionExportDataFormat } from '../../../../config/export';
import { DesktopBettingDrawer, MobileBettingDrawer } from '../common/betting-popup';

function MarketSelectionDropdown({marketSelection,onChangeFn,width=null}){

    return (
        <SingleSelect 
            label="Market"
            value={marketSelection}
            availableValues={validMarkets.map(value => value.key)}
            availableValuesDisplay={validMarkets.map(value => value.display)}
            onChangeFn={onChangeFn}
            width={width}
            size='small'
        />
    )
}

function TeamPredictionResultsFilter(
    {marketSelection,sportsbookSelection,setSportsbookSelection,
    largeScreen,handleColumnVisibilityToggle,
    predictionData,setFilteredPredictionsData}
){

    ////////////////////////////////////////////////////////
    // Filters
    // Note: Market and Sportsbook filters are tied into the
    // table and can not be moved into this component
    ////////////////////////////////////////////////////////
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({predicted_odds_values}) => {
        if (!showValueBetsOnly) {
            return true;
        }
        else {
            if (marketSelection === 'spreads'){
                return predicted_odds_values[marketSelection]['home']['value_bool'] || predicted_odds_values[marketSelection]['away']['value_bool'];
        
            } else if (marketSelection === 'totals') {
                return predicted_odds_values[marketSelection]['over']['value_bool'] || predicted_odds_values[marketSelection]['under']['value_bool'];

            }
            else {
                // Should never occur
                return true;
            }
        }
    }

    // Spreads Market Filter
    const availableSideTypes = ["All","Favorite","Underdog"];
    const [sideType,setSideType] = useState("All");
    const sideTypeFilterFn = ({predicted_odds,best_odds}) => {
        const favorite = predicted_odds.spreads.away.point < 0 ? 'away' : 'home';
        const underdog = favorite === 'away' ? 'home' : 'away';

        if (sideType === 'All') {
            return true;
        }
        else {
            const sideTypeFilter = sideType === 'Favorite' ? favorite : underdog;
            return predicted_odds.spreads[sideTypeFilter].point < best_odds.spreads[sideTypeFilter].consensus
        }
    }

    // Totals Market Filter
    const availableTotalTypes = ["All","Over","Under"];
    const [totalType,setTotalType] = useState("All");
    const totalTypeFilterFn = ({predicted_odds,best_odds}) => {
        if (totalType === 'All') {
            return true;
        }
        else {
            if (totalType === 'Over') {
                const totalTypeFilter = 'over';
                return predicted_odds.totals[totalTypeFilter].point > best_odds.totals[totalTypeFilter].consensus
            } else {
                // Unders
                const totalTypeFilter = 'under';
                return predicted_odds.totals[totalTypeFilter].point < best_odds.totals[totalTypeFilter].consensus
            }
        }
    }

    // Future Events filter
    // Only show events that haven't started yet if enabled
    const [showFutureEventsOnly,setShowFutureEventsOnly] = useState(false);
    const futureEventsFilterFn = ({matchup}) => 
        showFutureEventsOnly && matchup.start_time != null ? dayjs(matchup.start_time) > dayjs() : true;

    // Show events with odds
    const [hideEventsWithoutOdds,setHideEventsWithoutOdds] = useState(false);
    const oddsExistFilterFn = ({best_odds,matchup}) => {
        // If we were able to determine a pick for this market there must have been odds
        return hideEventsWithoutOdds ? matchup.pick[marketSelection] != null  : true;
    }
    
    // Delta (%)
    const minPercentageDelta = 0;
    const maxPercentageDelta = 1; // 100%
    const [deltaPercentage,setDeltaPercentage] = useState([minPercentageDelta,maxPercentageDelta]);
    const deltaPercentageFormat = (value) =>
        value === maxPercentageDelta ? `${(maxPercentageDelta*100).toFixed(0)}+%` : `${(value*100).toFixed(0)}%`;
    const deltaPercentageFilterFn = ({predicted_odds,best_odds,matchup}) => {

        // Fairly complex but since each row is 2 bets (each side), we need to determine
        // the pick and then look at that prediction relative to its respective point value
        // within the market
        const pick = matchup.pick[marketSelection];

        if (pick == null) {
            // No odds, can't filter out
            return true;
        }

        // Odds data must exist because pick was not null
        const predicted_point = predicted_odds[marketSelection][pick]['point'];
        const point = best_odds[marketSelection][pick]['point'];

        // Prevent divide by zero by just setting point to 1 for rough
        // estimate of delta
        const safePoint = point === 0 ? 1 : point;

        const betDeltaPercentage = Math.abs((predicted_point - point) / safePoint);
        if (deltaPercentage[1] === maxPercentageDelta) {
            // If the max is 100%, there will be deltas beyond that
            // so 100% is being qualified as 100+%
            return deltaPercentage[0] <= betDeltaPercentage;
        }
        else {
            // Find bet deltas within range
            return deltaPercentage[0] <= betDeltaPercentage && betDeltaPercentage <= deltaPercentage[1];
        }
    };


    // Delta (mag)
    const minMagnitudeDelta = 0;
    const maxMagnitudeDelta = 20;
    const [deltaMagnitude,setDeltaMagnitude] = useState([minMagnitudeDelta,maxMagnitudeDelta]);
    const deltaMagnitudeFormat = (value) =>
        value === maxMagnitudeDelta ? `${(maxMagnitudeDelta).toFixed(1)}+` : `${(value).toFixed(1)}`;
    const deltaMagnitudeFilterFn = ({predicted_odds,best_odds,matchup}) => {

        // Fairly complex but since each row is 2 bets (each side), we need to determine
        // the pick and then look at that prediction relative to its respective point value
        // within the market
        const pick = matchup.pick[marketSelection];

        if (pick == null) {
            // No odds, can't filter out
            return true;
        }

        // Odds data must exist because pick was not null
        const predicted_point = predicted_odds[marketSelection][pick]['point'];
        const point = best_odds[marketSelection][pick]['point'];
        
        const betDeltaMagnitude = Math.abs(predicted_point - point);
        if (deltaMagnitude[1] === maxMagnitudeDelta) {
            // If upper end equals max, there will be deltas beyond that
            // so max is being qualified as max+
            return deltaMagnitude[0] <= betDeltaMagnitude;
        }
        else {
            // Find bet deltas within range
            return deltaMagnitude[0] <= betDeltaMagnitude && betDeltaMagnitude <= deltaMagnitude[1];
        }
    };

    // If columns change, we need to reapply filters
    useEffect(() => {
        if (predictionData.length !== 0) {
            let filteredData = structuredClone(predictionData).filter(valueFilterFn);
            filteredData = filteredData.filter(sideTypeFilterFn);
            filteredData = filteredData.filter(totalTypeFilterFn);
            filteredData = filteredData.filter(futureEventsFilterFn);
            filteredData = filteredData.filter(oddsExistFilterFn);
            filteredData = filteredData.filter(deltaPercentageFilterFn);
            filteredData = filteredData.filter(deltaMagnitudeFilterFn);
            setFilteredPredictionsData(filteredData);
        }
     },[predictionData,marketSelection,showValueBetsOnly,sideType,totalType,hideEventsWithoutOdds,showFutureEventsOnly,deltaPercentage,deltaMagnitude]);


    // Always reset the type filters on market switch
     useEffect(() => {
        setSideType("All");
        setTotalType("All");
     },[marketSelection]);
     
    return (
        <>
            <FilterMenu>
                {/* The below items are only shown on small screens */}
                {!largeScreen &&
                <FilterMenuItem>
                    <MarketSelectionDropdown marketSelection={marketSelection} onChangeFn={handleColumnVisibilityToggle} />
                </FilterMenuItem>
                }
                {!largeScreen &&
                <FilterMenuItem>
                    <SingleSelect 
                    label="Sportsbook"
                    value={sportsbookSelection}
                    availableValues={['best_odds'].concat(validSportsbooks.map(value => value.key))}
                    availableValuesDisplay={['Best Odds'].concat(validSportsbooks.map(value => value.display))}
                    onChangeFn={(event) => {setSportsbookSelection(event.target.value)}}
                    size='small'
                    />
                </FilterMenuItem>
                }

                {/* The below items are always shown in the filter */}
                <FilterMenuItem>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showValueBetsOnly}
                            onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        }
                    label="Show value bets only"
                    />
                </FilterMenuItem>
                        
                {marketSelection === 'spreads' &&
                <FilterMenuItem>
                    <SingleSelect
                    label="Bet Type"
                    value={sideType}
                    onChangeFn={(event) => setSideType(event.target.value)}
                    availableValues={availableSideTypes}
                    size='small'
                    />
                </FilterMenuItem>
                }

                {marketSelection === 'totals' &&
                <FilterMenuItem>
                    <SingleSelect
                    label="Bet Type"
                    value={totalType}
                    onChangeFn={(event) => setTotalType(event.target.value)}
                    availableValues={availableTotalTypes}
                    size='small'
                    />
                </FilterMenuItem>
                }

                <FilterMenuItem>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showFutureEventsOnly}
                            onChange={(event) => setShowFutureEventsOnly(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        }
                    label="Show future events only"
                    />
                </FilterMenuItem>

                <FilterMenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideEventsWithoutOdds}
                                onChange={(event) => setHideEventsWithoutOdds(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    label="Only show events with odds"
                    />
                </FilterMenuItem>

                <SliderMenuItem
                title={`Delta (Pct): ${deltaPercentageFormat(deltaPercentage[0])} - ${deltaPercentageFormat(deltaPercentage[1])}`}
                tooltipText="The percentage difference between the model prediction and the sportsbooks line of the bet.
                Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
                >
                    <Slider
                        getAriaLabel={()=>"delta-percentage-split-slider"}
                        value={deltaPercentage}
                        onChange={(event) => setDeltaPercentage(event.target.value)}
                        step={0.01}
                        min={minPercentageDelta}
                        max={maxPercentageDelta}
                        valueLabelDisplay="off"
                        valueLabelFormat={deltaPercentageFormat}
                    />
                </SliderMenuItem>

                <SliderMenuItem
                title={`Delta (Abs): ${deltaMagnitudeFormat(deltaMagnitude[0])} - ${deltaMagnitudeFormat(deltaMagnitude[1])}`}
                tooltipText="The magnitude difference between the model prediction and the sportsbooks line of the bet.
                Any deltas above the maximum are also included when the top of the range is set to the maximum on the slider."
                >
                    <Slider
                        getAriaLabel={()=>"delta-percentage-split-slider"}
                        value={deltaMagnitude}
                        onChange={(event) => setDeltaMagnitude(event.target.value)}
                        step={0.5}
                        min={minMagnitudeDelta}
                        max={maxMagnitudeDelta}
                        valueLabelDisplay="off"
                        valueLabelFormat={deltaMagnitudeFormat}
                            
                    />
                </SliderMenuItem>

            </FilterMenu>
        </>
    )
}

function TeamPredictionResultsSortBy({filteredData,setFilteredData,marketSelection}){

        ////////////////////////////////////////////////////////
        // Sort functions
        ////////////////////////////////////////////////////////
    
        const options = [
            {
                title: "Highest Value",
                sortFn: (a,b) => 
                    descendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
            {
                title: "Lowest Value",
                sortFn: (a,b) => 
                    ascendingSortFunction(a.predicted_odds_values[marketSelection].max_value,b.predicted_odds_values[marketSelection].max_value)
            },
            {
                title: "Earliest",
                sortFn: (a,b) => 
                    ascendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
            },
            {
                title: "Latest",
                sortFn: (a,b) => 
                    descendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
            },
        ];
        
        // Set selected index to null. Not sorting anything to start.
        const [selectedIndex,setSelectedIndex] = useState(null);
        
        const sortData = (sortFn) => {
            const localFilteredData = filteredData.slice().sort(sortFn);
            setFilteredData(localFilteredData);
        }


        // If columns change, we need to reapply sorting
        useEffect(() => {
            if (selectedIndex !== null) {
                sortData(options[selectedIndex].sortFn);
            }
         },[marketSelection]);

        const handleChange = (event) => {
            const index = event.currentTarget.value;
            sortData(options[index].sortFn);
            setSelectedIndex(index);
        }
    
        return (
            <SortByMenu
            selectedIndex={selectedIndex}
            options={options}
            onChangeFn={handleChange}
            />
        )
}



export function TeamPredictionResultsTable({league,startTime,endTime,predictionData,loading}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const screenTooSmallForShare = useMediaQuery('(max-width:350px)');

    const columnVisibilityDict = {
        "spreads" : {
            "predicted_spreads" : true,
            "best_odds_spreads"  : true,
            "fanduel_spreads"   : true,
            "draftkings_spreads" : true,
            "betmgm_spreads"    : true,
            "espnbet_spreads"   : true,
            "betrivers_spreads"   : true,
            "betonlineag_spreads"   : true,
            "bovada_spreads"     : true,
            "ballybet_spreads"   : true,
            "hardrockbet_spreads" : true,
            "mybookieag_spreads"  : true,
            "caesars_spreads"   : true,
            "betus_spreads"   : true,
            "lowvigag_spreads"   : true,
            'novig_spreads'       : true,
            'prophetx_spreads'    : true,
            "betparx_spreads"   : true,
            
            "predicted_totals"  : false,
            "best_odds_totals"  : false,
            "fanduel_totals"   : false,
            "draftkings_totals" : false,
            "betmgm_totals"    : false,
            "espnbet_totals"   : false,
            "betrivers_totals"   : false,
            "betonlineag_totals"   : false,
            "bovada_totals"     : false,
            "ballybet_totals"   : false,
            "hardrockbet_totals" : false,
            "mybookieag_totals"  : false,
            "caesars_totals"   : false,
            "betus_totals"   : false,
            "lowvigag_totals"   : false,
            'novig_totals'       : false,
            'prophetx_totals'   : false,
            "betparx_totals"   : false,
            
            
        },
        "totals" : {
            "predicted_spreads" : false,
            "best_odds_spreads"  : false,
            "fanduel_spreads"   : false,
            "draftkings_spreads" : false,
            "betmgm_spreads"    : false,
            "espnbet_spreads"   : false,
            "betrivers_spreads"   : false,
            "betonlineag_spreads"   : false,
            "bovada_spreads"     : false,
            "ballybet_spreads"   : false,
            "hardrockbet_spreads" : false,
            "mybookieag_spreads"  : false,
            "caesars_spreads"   : false,
            "betus_spreads"   : false,
            "lowvigag_spreads"   : false,
            'novig_spreads'       : false,
            'prophetx_spreads'    : false,
            "betparx_spreads"   : false,
            
            "predicted_totals"  : true,
            "best_odds_totals"  : true,
            "fanduel_totals"   : true,
            "draftkings_totals" : true,
            "betmgm_totals"    : true,
            "espnbet_totals"   : true,
            "betrivers_totals"   : true,
            "betonlineag_totals"   : true,
            "bovada_totals"     : true,
            "ballybet_totals"   : true,
            "hardrockbet_totals" : true,
            "mybookieag_totals"  : true,
            "caesars_totals"   : true,
            "betus_totals"   : true,
            "lowvigag_totals"   : true,
            'novig_totals'       : true,
            'prophetx_totals'   : true,
            "betparx_totals"   : true,
            
        }
    }

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////

    // Sort by start time on initial set
    const [filteredPredictionsData,setFilteredPredictionsData] = useState(
        predictionData.sort( (a,b) =>
            ascendingSortFunction(dayjs(a.matchup.start_time).unix(),dayjs(b.matchup.start_time).unix())
        )
    );

    const [marketSelection,setMarketSelection] = useState("spreads");
    const [sportsbookSelection,setSportsbookSelection] = useState("best_odds");
    const [columnVisibility,setColumnVisibility] = useState(columnVisibilityDict[marketSelection]);

    // Share States - I know there is a lot
    const [tableRef,setTableRef] = useState(null);
    const [enableExport,setEnableExport] = useState(false);
    const [rowSelection,setRowSelection] = useState({});
    const [enableRowSelection,setEnableRowSelection] = useState(false);
    const [exportLoading,setExportLoading] = useState(false);
    const [sharePopupOpen,setSharePopupOpen] = useState(false);
    const [exportDataBlob,setExportDataBlob] = useState(null);
    const [warningSnackbarOpen,setWarningSnackbarOpen] = useState(false);
    const [preShareFilteredPredictionsData,setPreShareFilteredPredictionsData] = useState([]);
    const [exportDataFormat,setExportDataFormat] = useState(defaultPredictionExportDataFormat);

    // Bet Tracking states
    const [pendingBets,setPendingBets] = useState([]);
    const [betDrawerOpen,setBetDrawerOpen] = useState(false);

    const handleColumnVisibilityToggle = (event) => {
        setMarketSelection(event.target.value);

        updateColumnVisibilityResponsive(event.target.value);
    }

    const updateColumnVisibilityResponsive = (localMarketSelection) => {
        if (largeScreen) {
            setColumnVisibility(columnVisibilityDict[localMarketSelection]);
        }
        else {
            let localColumnVisibility = columnVisibilityDict[localMarketSelection];
            for (const [key, value] of Object.entries(localColumnVisibility)) {
                if (!key.includes('predicted') && !key.includes(sportsbookSelection)){
                    localColumnVisibility[key] = false;
                }
            }
            setColumnVisibility(localColumnVisibility);
        }
    }

    ////////////////////////////////////////////////////////
    // Column Definitions
    ////////////////////////////////////////////////////////
    const oddsColumnWidth = largeScreen ? DesktopOddsColumnWidth : MobileOddsColumnWidth;

    const columns = useMemo(
      () => [
        {
            // Set the row value for search, display is overriden by Cell below
            accessorFn: (row) => row.matchup.away.display_name + " " + row.matchup.home.display_name, 
            id: 'matchup',
            header: null,
            size: largeScreen ? 315 : 150,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Cell: ({ row }) =>
                <>
                    <GamesDisplay matchup={row.original.matchup} league={league} largeScreen={largeScreen} />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.spreads, 
            id: 'predicted_spreads',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <SpreadBlockDisplay spreads={row.original.predicted_odds.spreads} 
                        awayHasValue={row.original.predicted_odds_values.spreads.away.value_bool}
                        homeHasValue={row.original.predicted_odds_values.spreads.home.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.predicted_odds.totals, 
            id: 'predicted_totals',
            header: 'Prediction',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: true,
            Header: ({ column }) => (
                <>
                    <Typography variant={largeScreen ? "h6" : "body2"}>{column.columnDef.header}</Typography>
                </>
            ),
            Cell: ({ row }) =>
                <>
                    <TotalBlockDisplay totals={row.original.predicted_odds.totals} 
                        overHasValue={row.original.predicted_odds_values.totals.over.value_bool} 
                        underHasValue={row.original.predicted_odds_values.totals.under.value_bool}
                    />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_spreads',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsSpreadBlockDisplay best_odds_spreads={row.original.best_odds.spreads} 
                    awayBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: 'best',
                        market: 'spreads',
                        pick: 'away',
                        stake: 1.0
                    },...pendingBets])}
                    homeBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: 'best',
                        market: 'spreads',
                        pick: 'home',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
        },
        {
            accessorFn: (row) => row.best_odds, 
            id: 'best_odds_totals',
            header: 'Best Odds',
            size: oddsColumnWidth,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            Header: ({ column }) => (
                  <>
                      <Typography variant={largeScreen ? "h6" : "caption"}>{column.columnDef.header}</Typography>
                  </>
              ),
            Cell: ({ row }) =>
                <>
                    <BestOddsTotalBlockDisplay best_odds_totals={row.original.best_odds.totals} 
                    overBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: 'best',
                        market: 'totals',
                        pick: 'over',
                        stake: 1.0
                    },...pendingBets])}
                    underBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: 'best',
                        market: 'totals',
                        pick: 'under',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
        },
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].spreads, 
            id: `${sportsbook['key']}_spreads`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <SpreadBlockDisplay spreads={row.original.sportsbooks[sportsbook.display].spreads} 
                    bestSportsbookAway={row.original.best_odds.spreads.away.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookHome={row.original.best_odds.spreads.home.sportsbooks.includes(sportsbook.display)}
                    awayBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: sportsbook.display,
                        market: 'spreads',
                        pick: 'away',
                        stake: 1.0
                    },...pendingBets])}
                    homeBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: sportsbook.display,
                        market: 'spreads',
                        pick: 'home',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
            )
            }
        ))
        ,
        ...validSportsbooks.map((sportsbook,index) => (
            {
            accessorFn: (row) => row.sportsbooks[sportsbook.display].totals, 
            id: `${sportsbook['key']}_totals`,
            header: sportsbook.key,
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnPinning: false,
            size: oddsColumnWidth,
            Header: ({ column }) => (
                <>
                    <ResponsiveSportsbookLogo sportsbook={column.columnDef.header} desktopWidth={"100%"} mobileWidth={30} largeScreen={largeScreen} />
                </>
            ),
            Cell: ({ row }) => (
                <>
                    <TotalBlockDisplay totals={row.original.sportsbooks[sportsbook.display].totals} 
                    bestSportsbookOver={row.original.best_odds.totals.over.sportsbooks.includes(sportsbook.display)}
                    bestSportsbookUnder={row.original.best_odds.totals.under.sportsbooks.includes(sportsbook.display)}
                    overBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: sportsbook.display,
                        market: 'totals',
                        pick: 'over',
                        stake: 1.0
                    },...pendingBets])}
                    underBetOnClickFn={() => setPendingBets(pendingBets => [
                    {
                        ...row.original,
                        league: league,
                        sportsbook: sportsbook.display,
                        market: 'totals',
                        pick: 'under',
                        stake: 1.0
                    },...pendingBets])}
                    />
                </>
            )
            }
        ))

        ],
        [largeScreen]
    );

    useEffect(() => {
        updateColumnVisibilityResponsive(marketSelection);
    }, [largeScreen,marketSelection,sportsbookSelection]);

    ////////////////////////////////////////////////////////
    // Share functions
    ////////////////////////////////////////////////////////

    const setupExportData = (table) => {
        // Sets up table to allow users to select the rows they
        // want to export

        if (screenTooSmallForShare) {
            setWarningSnackbarOpen(true);
            return;
        }

        setTableRef(table);
        setEnableExport(true);
        setPreShareFilteredPredictionsData(filteredPredictionsData);
        setEnableRowSelection(true);
    }

    const exportData = async (shareData) => {
        // Only use Web Share API when mobile
        if (navigator.share !== null && !largeScreen) {
            try {
              await navigator
                .share(shareData)
                .then(() => {
                  setEnableRowSelection(true);
                  setFilteredPredictionsData(preShareFilteredPredictionsData);
                  setExportLoading(false);
              });
            } catch (error) {
              // Web Share Api exited with bad status. This occurs
              // when a share is cancelled though
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
            }
        } else {
              // fallback code for unsupported browsers and desktop browsers
              setSharePopupOpen(true);
  
              setEnableRowSelection(true);
              setFilteredPredictionsData(preShareFilteredPredictionsData);
              setExportLoading(false);
        }

    }

    const sharePng = async (canvas) => {

        const imageUrl = canvas.toDataURL();
        const blob = await (await fetch(imageUrl)).blob();
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.png');

        exportData(shareData);
        
    };

    const shareCsv = async (selectedRowIndexes) => {

        // Convert filtered predictions data into a csv
        let rowsToExport = structuredClone(filteredPredictionsData).filter((_,index) => selectedRowIndexes.includes(index.toString()));

        // Remove some data before export to make data consistent length
        // and to protect some of the information from being easily exported.
        rowsToExport = filterPredictionsExportData(rowsToExport,true);

        const blob = jsonToCsvBlob(rowsToExport)
        setExportDataBlob(blob);
        const shareData = blobToShareData(blob,'predictions.csv');

        exportData(shareData);
    };

    const handleExportData = () => {
        // Do not try to export nothing
        if (Object.keys(rowSelection).length == 0) {
            return;
        }

        setExportLoading(true);

        // Set up table for canvas capture
        // Filter to show only selected rows and disable row selection to hide that column
        // and styling
        const selectedRowIndexes = Object.keys(rowSelection);
        setFilteredPredictionsData(filteredPredictionsData.slice().filter((_,index) => selectedRowIndexes.includes(index.toString())));
        setRowSelection({});
        setEnableRowSelection(false);

        switch (exportDataFormat) {
            case "png":
                // Wait while canvas setup is rendering
                setTimeout(() => {

                const ref = tableRef.refs.tableContainerRef.current;
                
                html2canvas(ref,{
                    height: document.outerHeight,
                    windowHeight: document.outerHeight,
                    scale: 5,
                    useCORS: true
                }).then(canvas => {
                    sharePng(canvas);
                });

                }, 1000);
                break;

            case "csv":
                shareCsv(selectedRowIndexes);
                break;

            default:
                // Should never occur
                break;

        }
        
    }

    const handleCancelExportData = () => {
        setRowSelection({});
        setEnableExport(false);
        setEnableRowSelection(false);
    }

    ////////////////////////////////////////////////////////
    // Bet Tracking functions
    ////////////////////////////////////////////////////////

    useEffect(() => {
        // The behavior will differ here depending on desktop and mobile
        // For desktop, we always want to open the drawer when a bet is place
        // For mobile, we only want to FORCE open it if pendingBets length is 1.
        // This only occurs when the first bet is added and we want to show the user
        // their bet slip. When we go from a length of 2 to 1, it won't matter because
        // the bet slip is already open.
        //
        // So the second part of the logic in laymans terms is that if on mobile, we will
        // only FORCE open the drawer when the length is 1 or we will keep it open if the drawer
        // is already open
        setBetDrawerOpen(pendingBets.length !== 0 && (largeScreen || (pendingBets.length === 1 || betDrawerOpen)));
    }, [pendingBets]);

    return (
    <>
      <Box component='div' sx={{width: largeScreen ? '90%' : null}}>
        <BasePredictionResultsMRT

            columns={columns}
            data={filteredPredictionsData}

            loading={loading}
            theme={theme}
            largeScreen={largeScreen}
            height={enableExport ? 'none' : tableHeight}

            // Top Toolbar custom actions
            renderTopToolbarCustomActions={({ table }) => {

                return (
                <Box component="div" sx={{ display: 'flex', gap: '0.5rem'}}>
                    <ExportPredictionsPopup 
                    open={sharePopupOpen} 
                    setOpen={setSharePopupOpen}
                    blob={exportDataBlob}
                    format={exportDataFormat}
                    />
                    <WarningSnackbar 
                    open={warningSnackbarOpen}
                    setOpen={setWarningSnackbarOpen}
                    message={"Screen too small to share predictions"} 
                    />
                    {enableExport ?
                    <>
                    <LoadingButton
                    loading={exportLoading}
                    startIcon={<ShareIcon />}
                    onClick={handleExportData} 
                    variant="contained"
                    sx={{backgroundColor: "success.dark"}}
                    >
                        Share
                    </LoadingButton>
                    <SingleSelect
                    label="format"
                    value={exportDataFormat}
                    availableValues={availablePredictionExportDataFormats}
                    onChangeFn={(event) => setExportDataFormat(event.target.value)}
                    width={80}
                    size='small'
                    />
                    <ResponsiveIconStartButton
                    text="Cancel"
                    icon={<CloseIcon />}
                    onClick={handleCancelExportData} 
                    sx={{backgroundColor: "error.dark"}}
                    />
                    </>
                    :
                    // Below is the default toolbar display

                    <>
                    {largeScreen && 
                    // The market selection dropdown is moved into the Filter
                    // for small screens
                    <MarketSelectionDropdown 
                    marketSelection={marketSelection} 
                    onChangeFn={handleColumnVisibilityToggle} 
                    width={150}
                    />
                    }
                    <TeamPredictionResultsFilter
                        marketSelection={marketSelection}
                        sportsbookSelection={sportsbookSelection}
                        setSportsbookSelection={setSportsbookSelection}
                        largeScreen={largeScreen}
                        handleColumnVisibilityToggle={handleColumnVisibilityToggle}
                        predictionData={predictionData}
                        setFilteredPredictionsData={setFilteredPredictionsData}
                    />
                    <TeamPredictionResultsSortBy 
                        filteredData={filteredPredictionsData}
                        setFilteredData={setFilteredPredictionsData}
                        marketSelection={marketSelection}
                    />
                    <ExportPredictionsButton setupExportData={() => setupExportData(table)} />

                    {largeScreen &&
                    <ResponsiveIconStartButton
                    text="Track" 
                    onClick={()=>setBetDrawerOpen(true)}
                    icon={<AssessmentIcon/>}
                    />
                    }

                    </>
                    }
                </Box>
                );
            }}
            
            state={{ columnVisibility, showSkeletons: loading, showGlobalFilter: true, rowSelection }}
            
            onColumnVisibilityChange={ setColumnVisibility }

            // Column Pinning
            enableColumnPinning={false}
            layoutMode='grid-no-grow'
            initialState= {{
                columnPinning: { left: ['mrt-row-select','matchup','predicted_spreads','predicted_totals'] },
                showGlobalFilter: true
            }}

            // Row selection
            enableRowSelection={enableRowSelection}
            onRowSelectionChange={setRowSelection}

          /> {/* End of table definition */}

            {largeScreen ?
            <>
            <DesktopBettingDrawer 
            open={betDrawerOpen} 
            setOpen={setBetDrawerOpen} 
            pendingBets={pendingBets} 
            setPendingBets={setPendingBets}
            modelType={"team"}
            />
            </>
            :
            <MobileBettingDrawer
            open={betDrawerOpen} 
            setOpen={setBetDrawerOpen} 
            pendingBets={pendingBets} 
            setPendingBets={setPendingBets}
            modelType={"team"}
            />
            }
        </Box>
    </>
    )
}


function GamesDisplay({matchup,league,largeScreen}) {

    const typographyVariant = largeScreen ? "body1": "body2";
    const logoWidth = largeScreen ? 25 : 20;
    const innerStackSpacing = largeScreen ? 1 : 0.75;

    const gamesRelativeWidth = 75;

    return (
        <Stack 
        direction="row" 
        height="100%" 
        width="100%" 
        justifyContent="center" 
        alignItems="center" 
        spacing={1}
        divider={<Divider orientation="vertical" />}
        >
            {largeScreen && 
            <Stack 
            direction="column" 
            width={`${100 - gamesRelativeWidth}%`}
            height="100%" 
            justifyContent="center" 
            alignItems="center"
            >
                <Typography variant="subtitle" sx={{color: 'text.secondary'}}>
                    {dayjs(matchup.start_time).format('MMM Do')}
                </Typography>
                <Typography variant="subtitle" sx={{color: 'text.secondary'}}>
                    {dayjs(matchup.start_time).format('LT')}
                </Typography>
            </Stack>
            }

            <Stack direction="column" height="100%" width={largeScreen ? `${gamesRelativeWidth}%` : '100%'} justifyContent="center" spacing={1}>

                <Stack height="100%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={`${matchup.away.location} ${matchup.away.nickname}`} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? matchup.away.display_name : matchup.away.abbreviation}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {matchup.away.prediction}
                        </Typography>
                    </Stack>
                </Stack>
                
                <Stack height="100%" width="100%" justifyContent="center">
                    <Stack direction="row" width="100%" alignItems="center" spacing={innerStackSpacing}>
                        <TeamLogo team={`${matchup.home.location} ${matchup.home.nickname}`} league={league} width={logoWidth}/>
                        <Typography variant={typographyVariant}>
                            {largeScreen ? matchup.home.display_name : matchup.home.abbreviation}
                        </Typography>
                        <Box sx={{flexGrow: 1}} />
                        <Typography variant={typographyVariant}>
                            {matchup.home.prediction}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}