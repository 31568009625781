
import {useEffect,useState} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useNavigate} from 'react-router-dom';

import {
    Button,
    Checkbox,
    Grid,
    FormControlLabel,
    MenuItem,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import dayjs from 'dayjs';

import { teamModelsRequest, teamStoreRequest, teamModelTrackingRequest, teamBetsGetRequest } from '../../../../hooks/api/team';
import { playerModelsRequest, playerStoreRequest, playerModelTrackingRequest, playerBetsGetRequest } from '../../../../hooks/api/player';

import { TeamsPredictionsPopup, PlayerPredictionsPopup } from '../../../predictions/components/common/prediction-popups';
import { ProfileModelRenamePopup } from '../rename-popup';
import { DeleteModelPopup } from '../delete-popup';
import { FilterMenu, SortByMenu } from '../../../../components/filter-menu';
import { MultiSelect, SingleSelect } from '../../../../components/menus';

import { ModelCard } from './model-card';

import {descendingSortFunction,ascendingSortFunction} from '../../../../utils/sorting';
import { capitalize, uniqueValuesInDictList } from '../../../../utils/general';
import { ErrorSnackbar } from '../../../../components/snackbars';
import { HorizontalScrollStackWithArrows } from '../tracking/filters';
import { BetTypeToggle } from '../tracking/common';


export function ProfileModelsContainer({modelType}){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    ////////////////////////////////////////////////////////
    // States
    ////////////////////////////////////////////////////////
    const [modelsData, setModelsData] = useState([]);
    const [filteredModelsData,setFilteredModelsData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Bet Tracking
    const [showTracking,setShowTracking] = useState(false);
    const [trackedBetsByModel,setTrackedBetsByModel] = useState({});
    const [filteredTrackedBetsByModel,setFilteredTrackedBetsByModel] = useState({});
    const [trackingType,setTrackingType] = useState('Manual');

    // Error handling
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('Something went wrong');

    ////////////////////////////////////////////////////////
    // API calls
    ////////////////////////////////////////////////////////

    const handleModelsResponse = (response) => {
        setModelsData(response.data.modelsInformation);
        setFilteredModelsData(response.data.modelsInformation);
        setLoading(false);
    }
    const handleModelsError = (error) => {
      setLoading(false);
    }

    useEffect(() => {
        modelType === 'team' ? 
        teamModelsRequest(handleModelsResponse,handleModelsError)
        :
        playerModelsRequest(handleModelsResponse,handleModelsError)
    }, [loading]);

    const handleBetsResponse = (response) => {
        setTrackedBetsByModel(response.data.bets);
        setFilteredTrackedBetsByModel(response.data.bets);
    }
    const handleBetsError = (error) => {
        setErrorSnackbarMessage('Something went wrong');
        setErrorSnackbarOpen(true);
    }

    useEffect(() => {
        if (showTracking === true){
            // Don't use loading here. It's too quick and flash bangs users.
            const params = {
                tracking_type : trackingType.toLowerCase(),
                group_models: true
              };

            if (modelType === 'team') {
                teamBetsGetRequest(params,handleBetsResponse,handleBetsError);
            }
            else {
                // Player
                playerBetsGetRequest(params,handleBetsResponse,handleBetsError);
            }
            
        }
    },[showTracking,trackingType]);


    const gridSpacing = largeScreen ? 3 : 1;

    return (
        <>
        {!loading && modelsData.length === 0 &&
        <Stack width="100%" textAlign="center" alignItems="center" sx={{ p: 4 }}>
            <Typography variant="h6">No saved {modelType} models</Typography>
        </Stack>
        }

        {!loading && modelsData.length !== 0 &&
        <Stack
        direction="column"
        spacing={1}
        width="100%"
        sx={{
            mb: 2,
            pr:gridSpacing,
        }}
        >
        <Stack 
        direction="row" 
        justifyContent="space-between" 
        spacing={1}
        width="100%" 
        >

            <Button
            variant={showTracking ? "outlined" : "contained"}
            onClick={()=>setShowTracking(!showTracking)}
            startIcon={showTracking ? <VisibilityOffIcon /> : <VisibilityIcon />}
            >
                {showTracking ? "Hide" : "Tracking"}
            </Button>

            <Stack direction="row" spacing={1}>
                <ProfileModelsSortBy
                    filteredModelsData={filteredModelsData}
                    setFilteredModelsData={setFilteredModelsData}
                />
                <ProfileModelsFilter
                    modelType={modelType}
                    modelsData={modelsData}
                    setFilteredModelsData={setFilteredModelsData}
                />
            </Stack>
        </Stack>

        {showTracking &&
        <ModelsTrackingFilterMenu 
        modelType={modelType}
        trackingType={trackingType}
        setTrackingType={setTrackingType}
        trackedBetsByModel={trackedBetsByModel}
        setFilteredTrackedBetsByModel={setFilteredTrackedBetsByModel}
        />
        }
        </Stack>
        }

        <Grid container spacing={gridSpacing} direction="row"
        sx={{pr: gridSpacing, pb: gridSpacing}}
        >
            {loading ? 
            <GridItemsLoadingDisplay /> 
            : 
            <ProfileModelsContent
            modelType={modelType}
            filteredModelsData={filteredModelsData}
            setLoading={setLoading} 
            showTracking={showTracking}
            filteredTrackedBetsByModel={filteredTrackedBetsByModel}
            setErrorSnackbarOpen={setErrorSnackbarOpen}
            setErrorSnackbarMessage={setErrorSnackbarMessage}
            />
            }
        </Grid>

        <ErrorSnackbar 
        open={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        message={errorSnackbarMessage}
        />
        </>
    );

}

function ModelsTrackingFilterMenu(
    {modelType,trackingType,setTrackingType,trackedBetsByModel,setFilteredTrackedBetsByModel}
){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    ////////////////////////////////////////////////////////
    // Filters
    ////////////////////////////////////////////////////////

    // Time Filter
    const availableTimeFilters = ['All-Time','Last 30','Last 7','Yesterday','Today'];
    const [timeFilter,setTimeFilter] = useState(availableTimeFilters[0]);
    const timeFilterFn = ({start_time}) => {
        const now = dayjs();
        switch (timeFilter) {
            case 'All-Time':
                return true;
            case 'Last 30':
                return dayjs(start_time).isAfter(now.subtract(30,'day'));
            case 'Last 7':
                return dayjs(start_time).isAfter(now.subtract(7,'day'))
            case 'Yesterday':
                return dayjs(start_time).isAfter(now.subtract(1,'day').startOf('day')) && dayjs(start_time).isBefore(now.subtract(1,'day').endOf('day'))
            case 'Today':
                return dayjs(start_time).isAfter(now.startOf('day')) && dayjs(start_time).isBefore(now.endOf('day'))
            default:
                return true;
        }
    }

    // Value
    const [showValueBetsOnly,setShowValueBetsOnly] = useState(false);
    const valueFilterFn = ({value_bool}) => showValueBetsOnly ? value_bool : true;

    // Market. Set based on what is available in all bets.
    // Disabling this for player right now for simplicity
    const validMarkets = modelType === 'team' ? ['spreads','totals'] : ['totals'];
    const validMarketsDisplay = validMarkets.map(validMarket => capitalize(validMarket));
    const [marketSelection,setMarketSelection] = useState(validMarkets);
    const marketFilterFn = ({market}) => marketSelection.includes(market);

    // Bet Type within market
    // This is one of the only hardcoded elements of this filter. Need to add
    // an option here manually for new markets or bet types.
    const allValidBetTypesMap = {
        'favorite':'spreads','underdog':'spreads','over':'totals','under':'totals'
    };
    const validBetTypes = Object.keys(allValidBetTypesMap);
    const [betTypeSelection,setBetTypeSelection] = useState(validBetTypes);
    const betTypeFilterFn = ({market,pick,point}) => {

        if (market === 'spreads') {
            // Pick is away/home not favorite/underdog so logic required
            // to determine if pick is favorite or underdog just using point
            const pickType = point < 0 ? 'favorite' : 'underdog'
            return betTypeSelection.includes(pickType);
        }
        else if (market === 'totals') {
            // Pick matches bet type formatting
            return betTypeSelection.includes(pick);
        }
        else {
            // Should never occur
            return false;
        }
    }

    useEffect(() => {
        // Bets are grouped by model as dictionary object
        // Each key is a model id, each value is a list of bet objects
        //
        // So we have to iterate over the keys and filter each list
        // of bets based on the filter change

        const modelIdsToUpdate = Object.keys(trackedBetsByModel);
        let newFilteredTrackedBetsByModel = {};

        if (modelIdsToUpdate.length !== 0){
            modelIdsToUpdate.forEach(modelId => {
                let filteredData = trackedBetsByModel[modelId].slice();
                // Apply filters on bets list
                filteredData = filteredData.filter(timeFilterFn);
                filteredData = filteredData.filter(valueFilterFn);
                filteredData = filteredData.filter(marketFilterFn);
                filteredData = filteredData.filter(betTypeFilterFn);

                newFilteredTrackedBetsByModel[modelId] = filteredData;
            });

            setFilteredTrackedBetsByModel(newFilteredTrackedBetsByModel);
        }
        
    },[trackedBetsByModel,timeFilter,showValueBetsOnly,marketSelection,betTypeSelection]);

    const menuWidth = largeScreen ? 150 : 125;

    return (
        <HorizontalScrollStackWithArrows px={0} py={0.2}>
            <BetTypeToggle
            trackingType={trackingType} 
            setTrackingType={setTrackingType} 
            />
            <SingleSelect
            label={null}
            value={timeFilter}
            availableValues={availableTimeFilters}
            onChangeFn={(event) => setTimeFilter(event.target.value)}
            width={menuWidth}
            size='small'
            />

            <FormControlLabel
            control={
            <Checkbox
                checked={showValueBetsOnly}
                onChange={(event) => setShowValueBetsOnly(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
            />
            }
            label="Value Only"
            sx={{ 
                width: menuWidth, m: 0, backgroundColor: 'transparent',
                border: showValueBetsOnly ? 1.5 : 1, borderRadius: 1, borderColor: showValueBetsOnly ? 'primary.main' : '#57636f',
                '&:hover': { 
                    border: 1.5
                }
            }}
            />

            <MultiSelect
            label={"Markets"}
            values={marketSelection}
            setValues={setMarketSelection}
            availableValues={validMarkets}
            availableValuesDisplay={validMarketsDisplay}
            size='small'
            width={menuWidth}
            />

            <MultiSelect
            label={"Bet Type"}
            values={betTypeSelection}
            setValues={setBetTypeSelection}

            // Setting available values this way so that bet types for de-selected markets
            // do not show such as Favorites for Totals.
            availableValues={validBetTypes.filter(betType => marketSelection.includes(allValidBetTypesMap[betType]))}
            availableValuesDisplay={validBetTypes.filter(betType => marketSelection.includes(allValidBetTypesMap[betType])).map(validBetType => capitalize(validBetType))}
            size='small'
            width={menuWidth}
            />
        </HorizontalScrollStackWithArrows>
    )
}

function GridItemsLoadingDisplay(){

    // Create arbitrary list to iterate over
    // and create Grid items
    const gridItems = Array(6).fill(1);

    return (
        <>
        {gridItems.map((item, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
                <Skeleton
                variant="rounded"
                height={0}
                sx={{pt: '100%'}}
                />
            </Grid>
        ))}
        
        </>
    )
}

function ProfileModelsFilter({modelType,modelsData,setFilteredModelsData}){

    ////////////////////////////////////////////////////////
    // Filters
    ////////////////////////////////////////////////////////
    const uniqueLeagues = uniqueValuesInDictList(modelsData,"league");
    const [selectedLeagues,setSelectedLeagues] = useState(uniqueLeagues);
    const leagueFilterFn = ({league}) => selectedLeagues.includes(league);

    const uniquePositions = uniqueValuesInDictList(modelsData,"position");
    const [selectedPositions,setSelectedPositions] = useState(uniquePositions);
    const positionFilterFn = ({position}) => selectedPositions.includes(position);

    const uniqueProps = uniqueValuesInDictList(modelsData,"prop");
    const [selectedProp,setSelectedProp] = useState(uniqueProps);
    const propFilterFn = ({prop}) => selectedProp.includes(prop);

    useEffect(() => {
        let filteredData = modelsData.filter(leagueFilterFn);
        filteredData = filteredData.filter(positionFilterFn);
        filteredData = filteredData.filter(propFilterFn);

        setFilteredModelsData(filteredData);
     },[selectedLeagues,selectedPositions,selectedProp]);

    const handleFilterMenuReset = () => {
        setFilteredModelsData(modelsData);
        setSelectedLeagues(uniqueLeagues);
        setSelectedPositions(uniquePositions);
        setSelectedProp(uniqueProps);
    }

    return (
        <FilterMenu>
            <MenuItem>
                <MultiSelect
                    label="League"
                    values={selectedLeagues}
                    setValues={setSelectedLeagues}
                    availableValues={uniqueLeagues}
                />
            </MenuItem>
            {modelType === 'player' &&
            <MenuItem>
                <MultiSelect
                    label="Position"
                    values={selectedPositions}
                    setValues={setSelectedPositions}
                    availableValues={uniquePositions}
                />
            </MenuItem>
            }
            {modelType === 'player' &&
            <MenuItem>
                <MultiSelect
                    label="Prop"
                    values={selectedProp}
                    setValues={setSelectedProp}
                    availableValues={uniqueProps}
                />
            </MenuItem>
            }
            <MenuItem sx={{justifyContent: 'center'}}>
                <Button variant="contained" size="small" sx={{textTransform: 'none'}}onClick={handleFilterMenuReset}>
                    Reset
                </Button>
            </MenuItem>
        </FilterMenu>
    )
}

function ProfileModelsSortBy({filteredModelsData,setFilteredModelsData}){

    ////////////////////////////////////////////////////////
    // Sort functions
    ////////////////////////////////////////////////////////

    const options = [
        {
            title: "Most Recent",
            sortFn: (a,b) => 
                descendingSortFunction(dayjs(a.created).unix(),dayjs(b.created).unix())
        },
        {
            title: "Least Recent",
            sortFn: (a,b) => 
                ascendingSortFunction(dayjs(a.created).unix(),dayjs(b.created).unix())
        },
        {
            title: "Highest Grade",
            sortFn: (a,b) => 
                descendingSortFunction(a.overall_grade,b.overall_grade)
        },
        {
            title: "Lowest Grade",
            sortFn: (a,b) => 
                ascendingSortFunction(a.overall_grade,b.overall_grade)
        },
    ]

    const [selectedIndex,setSelectedIndex] = useState(0);
    
    const sortData = (sortFn) => {
        const localFilteredData = filteredModelsData.slice().sort(sortFn);
        setFilteredModelsData(localFilteredData);
    }

    const handleChange = (event) => {
        const index = event.currentTarget.value;
        sortData(options[index].sortFn);
        setSelectedIndex(index);
    }

    return (
        <SortByMenu
        selectedIndex={selectedIndex}
        options={options}
        onChangeFn={handleChange}
        />
    )
}

function ProfileModelsContent(
    {
        modelType,filteredModelsData,setLoading,showTracking,filteredTrackedBetsByModel,
        setErrorSnackbarOpen,setErrorSnackbarMessage
    }
){

    const navigate = useNavigate();

    const [selectedModelId,setSelectedModelId] = useState(false);
    const [selectedModelName,setSelectedModelName] = useState("");
    const [selectedLeague,setSelectedLeague] = useState(null);
    const [selectedPosition,setSelectedPosition] = useState(null);
    const [selectedProp,setSelectedProp] = useState(null);

    ////////////////////////////////////////////////////////
    // Popup or Action States
    ////////////////////////////////////////////////////////

    const [renamePopupOpen,setRenamePopupOpen] = useState(false);
    const [deletePopupOpen,setDeletePopupOpen] = useState(false);
    const [predictionsPopupOpen, setPredictionsPopupOpen] = useState(false);
    

    const handleRename = (modelId,modelName) => {
        setSelectedModelName(modelName);
        setSelectedModelId(modelId);
        setRenamePopupOpen(true);
    }

    const handleDelete = (modelId) => {
        setSelectedModelId(modelId);
        setDeletePopupOpen(true);
    }

    const handleEdit = (modelId) => {
        // Find model matching Id and set useStates with information
        const model = findModelById(modelId);

        // Set necessary fields before processing
        setSelectedLeague(model.league);

        const params = {
            "model_id":modelId
        }


        // Define locally to have access to model.league for redirect
        const handleStoreResponseForEdit = (response) => {
            navigate(`/create-${modelType}-model/${model.league}`, {state : {edit_enabled : true}});
        }
        const handleStoreErrorForEdit= (error) => {}

        // Set model id in session
        modelType === 'team' ?
        teamStoreRequest(params,handleStoreResponseForEdit,handleStoreErrorForEdit)
        :
        playerStoreRequest(params,handleStoreResponseForEdit,handleStoreErrorForEdit)
        
    }

    const handleTracking = (modelId,trackingEnabled) => {

        // Make API call to toggle tracking to the opposite of
        // current boolean state

        const params = {
            "model_id" : modelId,
            "enabled" : !trackingEnabled
        }

        const handleTrackingResposne = (response) => {
            // Trigger reload
            setLoading(true);
        }

        const handleTrackingError = (error) => {
            if (error.response){
                if (error.response.status === 401) {
                    // This could be invalid authorization (pro user), already maximum models
                    // automatically tracked, or user does not own this model
                    setErrorSnackbarMessage(error.response.data.message);
                }
                else {
                    setErrorSnackbarMessage('Something went wrong');
                }
            }
            setErrorSnackbarOpen(true);
        }

        // Set model id in session
        modelType === 'team' ?
        teamModelTrackingRequest(params,handleTrackingResposne,handleTrackingError)
        :
        playerModelTrackingRequest(params,handleTrackingResposne,handleTrackingError)

    }

    const handleStoreResponseForSummary = (response) => {
      navigate(`/model-summary/${modelType}`);
    }
    const handleStoreErrorForSummary = (error) => {}

    const handleSummary = (modelId) => {

        const params = {
            "model_id": modelId
        }

        // Set model id in session
        modelType === 'team' ?
        teamStoreRequest(params,handleStoreResponseForSummary,handleStoreErrorForSummary)
        :
        playerStoreRequest(params,handleStoreResponseForSummary,handleStoreErrorForSummary)
    }

    const handlePredictions = (modelId) => {
        // Find model matching model and set useStates with information
        const model = findModelById(modelId);

        // Set necessary fields before opening popup
        setSelectedLeague(model.league);
        setSelectedPosition(model.position); // Will just be null if team model
        setSelectedProp(model.prop); // Will just be null if team model
        setSelectedModelId(modelId);
  
        setPredictionsPopupOpen(true);
    };

    const createInfoDisplayName = (model) => {
        let displayStr  = '';
        if (modelType === 'team') {
            displayStr = model.league;
        } else {
            // Major hack to shorten the display name so that it doesn't wrap onto next line
            // just for this
            const propDislay = model.prop === 'Three Point Fieldgoals Made' ? '3pt FGs Made' : model.prop;

            displayStr = [model.position,propDislay,model.league].join(' | ');
        }
        return displayStr;
    }

    const findModelById = (modelId) => {
        let model = {};
        for(var i=0; i < filteredModelsData.length; i++){
            if(filteredModelsData[i].model_id === modelId){
                model = filteredModelsData[i];
                break;
            }
        }

        return model;
    }

    return (
        <>
        {filteredModelsData.map((model, index) => (
            <Grid item xs={12} sm={6} lg={4} key={model.name+index}>
                <ModelCard
                modelId={model.model_id}
                infoDisplay={createInfoDisplayName(model)}
                league={model.league}
                modelName={model.name}
                created={model.created}
                overallGrade={model.overall_grade}
                trackingEnabled={model.automatic_tracking_enabled}
                showTracking={showTracking}
                filteredBets={filteredTrackedBetsByModel[model.model_id] ? filteredTrackedBetsByModel[model.model_id] : []}

                handleEdit={handleEdit}
                handleRename={handleRename}
                handleDelete={handleDelete}
                handleTracking={handleTracking}

                handleSummary={handleSummary}
                handlePredictions={handlePredictions}
                />
            </Grid>
        ))}

        <ProfileModelRenamePopup open={renamePopupOpen} setOpen={setRenamePopupOpen} modelType={modelType} currentModelName={selectedModelName}
            modelId={selectedModelId} setLoading={setLoading} />
        <DeleteModelPopup open={deletePopupOpen} setOpen={setDeletePopupOpen}  modelType={modelType} modelId={selectedModelId} 
            setLoading={setLoading} />
        {modelType === 'team' ?
        <TeamsPredictionsPopup league={selectedLeague} modelId={selectedModelId} open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen} />
        :
        <PlayerPredictionsPopup league={selectedLeague} position={selectedPosition} prop={selectedProp} 
        modelId={selectedModelId} open={predictionsPopupOpen} setOpen={setPredictionsPopupOpen}
        />
        }
        </>
    )
}