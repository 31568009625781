import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Button,
    Card,
    Container,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import ReactGA from 'react-ga4';

import { leagueConfig, player_leagues, team_leagues } from '../../../config/leagues';
import { NetUnitsDisplay, RecordDisplay, UnitsRoiDisplay } from '../../profile/components/tracking/common';
import { LeaderboardFilter } from './filters';
import { capitalize } from '../../../utils/general';
import { descendingSortFunction } from '../../../utils/sorting';
import { teamBetsLeaderboardRequest } from '../../../hooks/api/team';
import { playerBetsLeaderboardRequest } from '../../../hooks/api/player';
import { UserPopup } from '../../auth/components/popups';
import PaymentOptionsPopup from '../../pricing/components/payment-popup';
import { SomethingWentWrongPopup } from '../../../components/responsive';
import { PlayerPredictionsPopup, TeamsPredictionsPopup } from '../../predictions/components/common/prediction-popups';
import { accountLeaderboardAccessRequest } from '../../../hooks/api/account';


export function LeaderboardContainer(){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const availableLeagues = {
        team : team_leagues,
        player : player_leagues
    }

    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(false); // Set to True if error occurs, data should never be empty

    const [modelType,setModelType] = useState('team'); // team or player
    const [league,setLeague] = useState(availableLeagues[modelType][1]);
    const [trackingType,setTrackingType] = useState('Manual'); // Manual or Automatic

    const [leaderboardData,setLeaderboardData] = useState([]);
    const [filteredLeaderboardData,setFilteredLeaderboardData] = useState(leaderboardData);

    const handleResponse = (response) => {
        let localLeaderboard = response.data.leaderboard;

        // Sort by ROI on client-side (here)
        localLeaderboard = localLeaderboard.sort( (a,b) => descendingSortFunction(a.units_roi,b.units_roi));

        setLeaderboardData(localLeaderboard);
        setFilteredLeaderboardData(localLeaderboard);
        setLoading(false);
    }

    const handleError = (error) => {
        // All error cases are handled the same
        // 404 is raised in rare occurrences of empty leaderboard
        // but we still want to handle this by simply saying
        // something went wrong
        setError(true);
        setLoading(false);
    }

    useEffect(()=> {
        setLoading(true);
        setError(false);

        const params = {
          tracking_type: trackingType.toLowerCase(),
          league: league
        }

        // Fetch leaderboard using model type, league, and tracking type
        if (modelType === 'team') {
          // Team
          teamBetsLeaderboardRequest(params,handleResponse,handleError);

        } else {
          // Player
          playerBetsLeaderboardRequest(params,handleResponse,handleError);
        }

    },[modelType,league,trackingType])

    return (
        <Container 
        maxWidth="sm" 
        disableGutters
        >
            <Stack 
            direction="column" 
            spacing={2} 
            width="100%"
            >
        
                <Stack direction={largeScreen ? "row" : "column"} spacing={2}>
                    <LeagueMenu
                    menuModelType="team"
                    modelType={modelType}
                    setModelType={setModelType}
                    league={league}
                    setLeague={setLeague}
                    />
                    <LeagueMenu
                    menuModelType="player"
                    modelType={modelType}
                    setModelType={setModelType}
                    league={league}
                    setLeague={setLeague}
                    />
                </Stack>

                

                <LeaderboardFilter
                leaderboardData={leaderboardData}
                setFilteredLeaderboardData={setFilteredLeaderboardData}
                trackingType={trackingType}
                setTrackingType={setTrackingType}
                />

                {/* Not loading, and error, display error message */}
                {error && !loading &&
                <Stack 
                width="100%"
                textAlign="center"
                sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 3,
                    py: 4
                }}
                >
                  <Typography>
                    League is out of season, no recent data...
                  </Typography> 
                </Stack>
                }

                {/* Not loading, and no error, display content */}
                {!error && !loading &&
                <Stack 
                direction="column" 
                spacing={1} 
                width="100%"
                >
                {filteredLeaderboardData.map((model,index) => (
                    <LeaderboardCard
                    key={index}
                    rank={index+1}
                    leaderboardModel={model}
                    league={league}
                    modelType={modelType}
                    />

                )) }
                </Stack>
                }

                {!error && !loading && filteredLeaderboardData.length === 0 &&
                <Stack 
                width="100%"
                textAlign="center"
                sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 3,
                    py: 4
                }}
                >
                  <Typography>
                    League is out of season, no recent data...
                  </Typography> 
                </Stack>
                }

                {/* Actively loading */}
                {loading &&
                <StackLoadingDisplay />
                }

            </Stack>
        </Container>
    )
}

function StackLoadingDisplay(){

  // Create arbitrary list to iterate over
  // and create Grid items
  const items = Array(4).fill(1);

  return (
      <>
      {items.map((item, index) => (
            <Skeleton
            key={index}
            variant="rounded"
            height={150}
            />
      ))}
      
      </>
  )
}

function LeagueMenu({menuModelType,modelType,setModelType,league,setLeague}){

    const handleMenuClick = (selectedLeague) => {
        setLeague(selectedLeague);
        setModelType(menuModelType);
    }

    return (
        <Stack direction="column" 
        spacing={0.5} 
        width="100%"
        >
        <Typography variant="h5">{capitalize(menuModelType)}</Typography>
        <Stack 
        direction="row" 
        spacing={1}
        width="100%"
        useFlexGap 
        flexWrap="wrap"
        >
            {Object.entries(leagueConfig).map(([key,leagueInfo]) =>
                (leagueInfo.player_enabled || menuModelType === 'team') &&     
                <Chip 
                key={key}
                variant={leagueInfo.display === league && modelType === menuModelType ? "filled" : "outlined"}
                label={leagueInfo.display}
                color="primary"
                clickable
                onClick={() => handleMenuClick(leagueInfo.display)}
                sx={{fontSize: {xs: "small",md:"large"}, p: {xs: 0.2,md: 1} }}
                />
            )}
        </Stack>
        </Stack>
    )
}

function LeaderboardCard({rank,leaderboardModel,league,modelType}){

    // Passing league because its in frontend format

    // Popups
    const [predictionsPopupOpen, setPredictionsPopupOpen] = useState(false);
    const [userPopupOpen,setUserPopupOpen] = useState(false);
    const [pricingPopupOpen,setPricingPopupOpen] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);

    const handleResponse = (response) => {
        // Open predictions popup
        setPredictionsPopupOpen(true);
    }

    const handleError = (error) => {
        if (error.response) {
            if (error.response.data.csrf) {
                // Not Logged In
                setUserPopupOpen(true);
                
                ReactGA.event({
                    category: 'Conversion',
                    action: 'Click',
                    label: "Leaderboard Predictions | Not Logged In",
                });
            } else if (error.response.status === 401 ) {
              // Unauthorized
              setPricingPopupOpen(true);

              ReactGA.event({
                category: 'Conversion',
                action: 'Click',
                label: "Leaderboard Predictions | Not Authorized",
              });
            }
            else {
              // Something went wrong
              setSomethingWentWrong(true);
            }
        } else {
            setSomethingWentWrong(true);
        }
    }

    const handlePredictionsButtonClicked = () => {
        setSomethingWentWrong(false);
        
        // Permission check for leaderboard predictions
        // Handle if user is not logged in, needs to upgrade
        // subscription (401), or (todo) user is out of predictions within
        // time window
        accountLeaderboardAccessRequest(handleResponse,handleError);
    }

    return (
        <>
        <Card sx={{borderRadius: 2}}>
            <CardHeader
                avatar={
                <IconButton 
                disableRipple
                // sx={{backgroundColor: 'primary.main',width:45}}
                >
                    {rank}
                </IconButton>
                }
                action={
                <Button
                onClick={() => handlePredictionsButtonClicked()}
                >
                    Predict
                </Button>
                }
                title={<Typography variant="h6">{leaderboardModel.model_name}</Typography>}
                subheader={
                    modelType === 'team' ?
                    `${leaderboardModel.username}` :
                    `${leaderboardModel.username} | ${leaderboardModel.position} | ${leaderboardModel.prop}`
                }
            />

            <CardContent sx={{'&.MuiCardContent-root:last-child' : {pt:0,pb:1.25}}}>
                <Stack 
                direction="row" 
                justifyContent="center" 
                alignItems="center" 
                spacing={1}
                >
                    <RecordDisplay 
                    loading={false}
                    wins={leaderboardModel.wins}
                    losses={leaderboardModel.losses}
                    pushes={0}
                    useStack={true}
                    />
                    <UnitsRoiDisplay
                    loading={false}
                    unitsRoi={leaderboardModel.units_roi}
                    />
                    <NetUnitsDisplay 
                    loading={false}
                    netUnits={leaderboardModel.net_units}
                    unitsRoi={leaderboardModel.units_roi}
                    useStack={true}
                    />
                </Stack>
            </CardContent>
        </Card>
        
        {modelType === 'team' ?
        <TeamsPredictionsPopup 
        league={league}
        modelId={leaderboardModel.model_id}
        open={predictionsPopupOpen}
        setOpen={setPredictionsPopupOpen}
        setModelId={true}
        validateUser={false}
        />
        :
        <PlayerPredictionsPopup
        league={league}
        position={leaderboardModel.position}
        prop={leaderboardModel.prop}
        modelId={leaderboardModel.model_id}
        open={predictionsPopupOpen}
        setOpen={setPredictionsPopupOpen}
        setModelId={true}
        validateUser={false}
        />
        }

        {/* This popup will only try to train a model and are not logged in*/}
        <UserPopup open={userPopupOpen} setOpen={setUserPopupOpen} />
        
        {/* This popup will only get rendered if we are logged in and are unauthorized */}
        <PaymentOptionsPopup open={pricingPopupOpen} setOpen={setPricingPopupOpen} isLoggedIn={true} unauthorized={true}/>
        
        {/* Something unexpected went wrong */}
        <SomethingWentWrongPopup open={somethingWentWrong} setOpen={setSomethingWentWrong} />
        </>
    )
}